import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Base from './base';
import AuthService from '../services/Auth.service';
import HandleToken from "../services/HandleToken.service";
import Footer from '../component/footer2';
import Root from '../hoc/Root.hoc';
import { withRouter } from "react-router";

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShow: false,
      marginTop: 0,
      email: "",
      password: ""
    }
  };

  componentDidMount() {
    
  }


  render() {

    //let marginTop = document.getElementById("signinFrom").clientHeight

    return (

      <>

        <Container fluid className="pt-4 pb-4" style={{ minHeight: '100px' }}>
          <Container>
            <Row>
              
            <Col xs={12} md={6}>
                <h3 className="header2 pb-2 pt-2 mb-2 theme-font"><span className="span-bold">FAQs</span></h3>
              </Col>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 1. What is Axenomic? </Card.Title>
        
                  <Card.Text className="pl-3 mb-2">
                  Axenomic is a simplification tool to reduce time in the purchase of products and supplies from Amazon. Sign up to create a custom purchasing catalog of standard products and supplies specific to your business or organization. No searching or browsing for which printer ink/toner, pens, paper, labels, or any other supply needed - just pull up your custom catalog and get back to work!</Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 
                    2. How much does Axenomic cost? </Card.Title>
                  <Card.Text className="pl-3 mb-2">
                    Axenomic is FREE! You get all features at no cost. No, there's not a catch and products purchased won't cost more either! Payment options and personal details are not requested on Axenomic. All purchases and payments are handled by Amazon.</Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 
                    3. What's in it for Axenomic? </Card.Title>
                  <Card.Text className="pl-3 mb-2">
                    Axenomic is supported income generated from your purchases through affiliate marketing. Axenomic is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon. This doesn't increase your expenses one bit!</Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 
                    4. Where can I learn how to use Axenomic? </Card.Title>
                  <Card.Text className="pl-3 mb-2">
                    A walkthrough video is available at ________________________</Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 5. Can business's my product library be hidden to just my employees? </Card.Title>
                  <Card.Text className="pl-3 mb-2">
                  Yes! On the "Manage" page for your organization, there is a switch to toggle between "Public" and "Private".
                  </Card.Text>
                  <Card.Text className="pl-3 mb-2">
                   - When in "Public" mode, anyone who accesses the page can view the items you save, but will also enable quicker access for you and your employees to check-out.
                  </Card.Text>
                  <Card.Text className="pl-3 mb-2">
                   - When in "Private" mode, all product selections are hidden until the "Secret Key" is entered after clicking in the grey area. The "Secret Key" can be either be generated automatically or memorable key can be manually entered by clicking "Regenerate Key".
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 6. Where can I add products from? </Card.Title>
                  <Card.Text className="pl-3 mb-2">
                  You may access the millions of products available from Amazon! From the "Manage" page for your organization, new products are added from the "Add New" button. You can either search for products to add or enter the product URL directly. Note: the Amazon system does not populate all URLs through its software interface, so a URL pasted into the field may not display (An unfortunate challenge).</Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 7. How are product purchases handled? </Card.Title>
                  <Card.Text className="pl-3 mb-2">
                  To maintain the security of your account and payment information, all purchases will be made directly on Amazon.com . To purchase items, browse your organization's custom product library and add the products to your cart. Once finished, click the cart icon at the top of the screen to review your cart and proceed to Amazon to finalize your purchase by clicking "Checkout to Amazon.com ".</Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-md-12 col-xs-12 custom-card">
                <Card.Body>
                  <Card.Title> 8. Where can I find the privacy and data usage policy?</Card.Title>
                  <Card.Text className="pl-3 mb-2">
                  - Privacy and Data Usage Policy Link</Card.Text>
                  <Card.Text className="pl-3 mb-2">
                  - Terms of Service Link</Card.Text>
                </Card.Body>
              </Card>



            </Row>
          </Container>
        </Container>
        <Container fluid className="footer">
            <Container>
          <Row>

            <Footer/>

            </Row>
           
        </Container>
      </Container>

      </>

    )
  }

}
export default withRouter(Root(Faq));