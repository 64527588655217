import React from 'react';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import BussinesKey from './bussinesskey';
import $ from 'jquery'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import Common from '../hoc/Common.hoc'
import CartAction from '../store/actions/Cart.action'
import ProductService from '../services/Product.service';
import config from '../config/Credential.config';
import EmployeeService from "../services/employeecart.service";
import cartwhite from "../resources/img/cart-white.png";

class QuickLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: require("../resources/img/product4.png"),
      title: "Card Title",
      desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
      rating: 3,
      key: Math.floor(Math.random() * 100),
      price: 150,
      isNew: false,
      gridWidth: "col-md-2",
      active: true,
      modalShow: false,
      _domain: "",
      isRemove: false,
      item: {},
      removeSelected: false,
      isBuying: true,
      cartModal: false,
      lastOrderItem: [],
      lastOrderShow: false
    }
  };


  static getDerivedStateFromProps(props, state) {
    return {
      image: props.image,
      title: props.title,
      desc: props.desc,
      rating: props.rating,
      price: props.price,
      isNew: props.isNew,
      gridWidth: props.gridWidth ? props.gridWidth : "col-md-2",
      active: props.active != undefined ? props.active : true,
      _domain: props._domain,
      isRemove: props.isRemove,
      item: props.item,
      isBuying: props.isBuying != undefined ? props.isBuying : true,
      lastOrderShow: props.lastOrderShow !=undefined ? props.lastOrderShow : false
    };

  }

  componentDidMount = () => {
    $(document).on('keydown', (evt) => {
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 27) {
        this.setState({ modalShow: false, cartModal: false })
      }
      return true;
    })
  }

  closeModal() {
    this.setState({ "modalShow": false })
  }

  redirectToAmazon = (product) => {
    let url = `https://www.amazon.com/gp/aws/cart/add.html?AWSAccessKeyId=${config.paapiCred.AccessKey}&AssociateTag=${config.paapiCred.PartnerTag}`
    
    if (product)
    url += `&ASIN.${1}=${product['ASIN']}&Quantity.${1}=${product['quantity'] ? product['quantity'] : '1'}`
    if (url) {
      return url
    }
  }

  buy = (item) => {
    let object = [{
      company_domain: this.props.hoc.getLocalStorage("_domain"),
      product_id: item['_id'],
      order_date: new Date().getTime()
    }];
    const getAmazonUrl = this.redirectToAmazon(item);
    if (getAmazonUrl) {
      EmployeeService.lastOrderDate(object).then(res => {
        if (res && res.success) {
          document.getElementById(`lastoder-${item['_id']}`).innerHTML = `Last ordered - ${this.utcToDate()}`;
          window.open(getAmazonUrl, '_blank');
        } else {
          this.props.hoc.promiseAlert(`Somthing went wrong`, "error"); 
        }
      });
    }
  }

  goToCart = (item, buy = false) => {
    this.props.onLoder();
    ProductService.checkAvaility(item.ASIN).then(async (data) => {
      if(data && data.success) {
        this.props.offLoder();
        let temp_cart = await localStorage.getItem("temp_cart") ? JSON.parse(localStorage.getItem("temp_cart")) : []
        let length = temp_cart.length;
        let pushStatus = true;
        await this.props.hoc.promiseAlert(`${item.DisplayValue} add to cart successfully!`, "success");
        if (temp_cart.length) {
          for (let t = 0; t < length; t++) {
            if (temp_cart[t]['_id'] == item['_id']) {
              temp_cart[t]['quantity'] = await temp_cart[t]['quantity'] ? +temp_cart[t]['quantity'] + 1 : 1;
            } else {
              for (let j = 0; j < temp_cart.length; j++) {
                if (temp_cart[j]['_id'] == item['_id']) {
                  pushStatus = false;
                }
              }
              if (pushStatus) {
                await temp_cart.push(item)
              }
            }
          }
        } else {
          await temp_cart.push(item)
        }
        await this.props.cart_action(temp_cart);
        await this.props.add_cart_count();
        if (buy) {
          this.props.history.push("/employeecart");
        }
      }
      else {
        this.props.hoc.promiseAlert(data.message, "error")
      }
    }).catch(err => this.props.hoc.promiseAlert("Something went wrong", "error"))
  }

  utcToDate(utc_date = false) {
    let d = utc_date ? new Date(utc_date) : new Date();
    return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`;
  }

  render() {
    return (
      <>
        <Card className={this.state.gridWidth + " custom-card col-xs-12 mb-5 float-left view-" + this.state.active}
          key={this.state.key}
          onClick={() => { !this.state.active ? this.setState({ modalShow: true }) : this.setState({ modalShow: false }) }}
        >
          {
            (this.state.active && this.state.isBuying) ?
            (this.state.lastOrderShow && this.state.item.lastOrderDate) ? <Card.Title className="last-order" id={"lastoder-"+this.props.item._id}>{`Last ordered - ${this.state.item.lastOrderDate.order_date ? this.utcToDate(this.state.item.lastOrderDate.order_date) :null}`}</Card.Title> : <Card.Title className="last-order" id={"lastoder-"+this.props.item._id} >Yet to be purchased</Card.Title> : ""
          }
          
          {!this.state.active ? <span className="overlay-blured" /> : null}
          <Figure className="link-image">
            <Figure.Image
              width="200px"
              //height={180}
              alt="171x180"
              src={this.state.image}
            />

          </Figure>
          <Card.Body className="p-0 link-body">
            <a href="#" className="link-text pb-2">
              <Card.Title className="theme-font mb-2 link-header">{this.state.title}</Card.Title>
              <Card.Title className="theme-font mb-2"> <span className="produxt-price">${this.state.price}</span></Card.Title>
            </a>
            {this.state.active && this.state.isBuying
              ?
              <div>
                <Button variant="primary" id={this.props.item._id} className="theme-button2 pl-3 pr-3" onClick={() => this.goToCart(this.props.item)}>
                  <img
                    src={cartwhite}
                    width="20"
                    height="20"
                    className="d-inline-block align-top mr-2"
                    alt="Axenomic logo"
                  />
                  Add to cart
                </Button>&nbsp;
                <Button variant="primary" id={this.props.item._id} className="theme-button2 pl-3 pr-3" onClick={() => this.buy(this.props.item)}>
                  <img
                    src={cartwhite}
                    width="20"
                    height="20"
                    className="d-inline-block align-top mr-2"
                    alt="Axenomic logo"
                  />
                  Buy now
                </Button>
              </div>
              : null
            }
            {
              this.state.isRemove ? <input className="quicklink-chk-box" type="checkbox" value={this.state.removeSelected ? true : false} onChange={async () => {
                await this.setState({ removeSelected: !this.state.removeSelected })
                this.props.removeFunc(this.state.item, this.state.removeSelected)
              }} style={{ margin: 20 }} /> : null
            }
          </Card.Body>

        </Card>
        <BussinesKey isModalShow={this.state.modalShow} _domain={this.state._domain} closeModal={() => { this.closeModal() }} accessProduct={() => { this.props.accessProduct() }} _login={(token) => { this.props._login(token) }} />
      </>
    )
  }

}

let mapDispatchToProps = (dispatch) => {
  return {
    cart_action: (data) => dispatch(CartAction.getTemporaryCartAction(data)),
    add_cart_count: () => dispatch(CartAction.cartSaveCount())
  }
}
let mapStateToProps = ({ cartReducer }) => {
  let { temp_cart } = cartReducer
  return {
    temp_cart
    // isLoggedIn: isLoggedIn
  }
}
export default Common(connect(mapStateToProps, mapDispatchToProps)(QuickLink));