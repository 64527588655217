import BaseService from './Base.service.js'
import { serviceUrl, employeSlug, businessSlug } from '../config/Credential.config'

class CompanyService extends BaseService {
    constructor(props) {
        super(props)
    }

    getCompanies = () => {
        this.serviceUrl = serviceUrl+employeSlug+'get-companies';
        return this.get().then((response) => response.json())
    }

    employeeLogin = (data) => {
        this.serviceUrl = serviceUrl+employeSlug+'login-employee';
        return this.post(data, null).then((response) => response.json())
    }

    privatePublicProduct (data) {
        this.serviceUrl = serviceUrl+employeSlug+'public-private';
        return this.post(data, null).then((response) => response.json())
    }

    editProfile (data) {
        this.serviceUrl = serviceUrl+businessSlug+'edit-profile';
        return this.post(data, null, true).then((response) => response.json())
    }

    employeeCart (data) {
        this.serviceUrl = serviceUrl+employeSlug+'cart-data';
        return this.post(data, null).then((response) => response.json())
    }

    deleteCompany(token) {
        this.serviceUrl = serviceUrl+businessSlug+'delete-company';
        return this.post({}, token).then((response) => response.json())
    }
}

export default new CompanyService();