import React, { Component } from 'react'
import {
    Link
} from "react-router-dom";
import Common from '../hoc/Common.hoc'
import AuthService from '../services/Auth.service'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class VerifyEmail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            verificationEmail: "",
            message: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (!props.match.params.email)
            this.props.history.push('/signin')
        else {
            return { verificationEmail: props.match.params.email }
        }
    }
    componentDidMount = () => {
        this.verifyEmail()
    }
    // componentDidUpdate = () => {
    //     this.verifyEmail()
    // }
    verifyEmail = () => {
        AuthService.verifyEmail(this.state.verificationEmail).then(async (data) => {
            if (data && data.success) {
                this.setState({ message: "Email has been verified successfully." })
            } else {
                if (data.message == "EMAIL_ALREADY_VERIFIED") {
                    await this.setState({ message: 'Email already verified' })
                    await this.props.hoc.promiseAlert(this.state.message, "success");
                    this.props.history.push("/signin");
                }
                else {
                    await this.setState({ message: 'Server problem. please click the email link again.' })
                    await this.props.hoc.promiseAlert(this.state.message, "error");
                }
            }
        }).catch(async err => {
            this.setState({ message: "Server problem. please click the email link again." })
            await this.props.hoc.promiseAlert("Server problem. please click the email link again.", "error");

        })
    }
    render() {
        return (
            <Container fluid className="pt-4 pb-4 signin theme-yellow" style={{ minHeight: window.innerHeight - 80 + 'px'}}>
                <Container id="mainContainer text-center">
                    <Row>
                        <Col style={{ papping: 20, justifyContent: 'center', alignItems: 'center' }} className="text-center pt-5 mt-5">
                            <h3 style={{ textAlign: 'center' }}>{this.state.message}</h3>
                            <Link to="/signin" className=" btn btn-primary theme-button3 p-2 pl-3 pr-3 mt-4" >Go To Login</Link>
                        </Col>
                    </Row>

                </Container>
            </Container>
        )
    }
}

export default Common(VerifyEmail);
