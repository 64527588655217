import React from 'react';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import DomainService from "../services/Domain.service";
import {serviceUrl} from "../config/Credential.config";
import pin from "../resources/img/pin.png";
import phonecall from "../resources/img/phone-call.png";
import default_images from "../resources/img/images.png";
import url from "../resources/img/url.png";

class Bussiness extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         companyInfo: {
            business_logo: "",
            business_name: "",
            business_tagline: "",
            description: "",
            address: "",
            contact_details: "",

         },
         data: 0,
         mrTop:0
      }
   };

   getCompany = () => {
		
		
   }

   // render () {
   //    // let business_logo = this.props.companyInfo.business_logo ? this.props.companyInfo.business_logo : "";
   //    return (
   //       <div>
   //          {JSON.stringify(this.state.companyInfo)}
   //          <Figure>
   //                <Figure.Image
   //                width="90%"
   //                height={180}
   //                alt="171x180"
   //                src={serviceUrl+"company_logo/"+this.state.companyInfo.business_logo}
   //             />
   //          </Figure>
   //       </div>
   //    )
      
   // }
   
   // static getDerivedStateFromProps(props, state) {
   //  
   //    return props;
   // }

   // getSnapshotBeforeUpdate (props, state) {
   //    
   //    return {
   //       ...this.state,
   //       companyInfo: props.companyInfo
   //    };
   // }

   componentDidMount() {
      if (this.props.match.params && this.props.match.params.domain) {
			let domain = this.props.match.params.domain;
			DomainService.getCompany(domain, "employee").then(res => {
				if (res && res.success) {
					this.setState({"companyInfo": res.data});
				}
			}).catch(err => {
				//
			});
		}
   //   this.setState({companyInfo: this.props.companyInfo})
   }
   // shouldComponentUpdate(newProps, newState) {
   //    return true;
   // }
   // componentDidUpdate(prevProps, prevState) {
   //    //
   // }
   // componentWillUnmount() {
   //    //
   // }

   setMargin(id){
    var elmnt = document.getElementById(id);
    var elmnt2 = document.getElementById("parentHeight");
    
    var mrTop = (parseFloat(elmnt2.offsetHeight) - parseFloat(elmnt.offsetHeight)) / 2;
    
    if(mrTop > 0){
      this.setState({mrTop:mrTop})
    }
    
  }

   render() {
      return (
         <>
            <Col xs={12} md={4} className="float-left" id="parentHeight">
               {
                  this.state.companyInfo.business_logo ? 
                     <Figure style={{marginTop:this.state.mrTop, width:"100%"}}>
                        <Figure.Image
                           width="90%"
                           height={180}
                           alt="171x180"
                           id={"img"+this.state.companyInfo.business_logo}
                           
                           onLoad={()=>{this.setMargin("img"+this.state.companyInfo.business_logo)}}
                           src={serviceUrl+"company_logo/"+this.state.companyInfo.business_logo}
                        />
                     </Figure>
                  : <Figure style={{marginTop:this.state.mrTop, width:"100%"}}>
                     <Figure.Image
                        width="90%"
                        height={180}
                        alt="171x180"
                        id={"img"+default_images}
                        
                        onLoad={()=>{this.setMargin("img"+default_images)}}
                        src={default_images}
                     />
                  </Figure>
               }

            </Col>

            <Col xs={12} md={8} className="float-left">

               <Card style={{ width: '100%' }} className="theme-yellow custom-card">
                  <Card.Body>
                     <Card.Title className="header pb-2 theme-font">
                        <span className="span-bold">{this.state.companyInfo.business_name ? this.state.companyInfo.business_name : ""}</span>
                     </Card.Title>
                     <Card.Subtitle className="mb-2 pb-2 theme-font subheader">
                        {this.state.companyInfo.business_tagline ? this.state.companyInfo.business_tagline : ""} 
                     </Card.Subtitle>
                     <Card.Text className="pb-0 theme-font basic-text">
                        {this.state.companyInfo.description ? this.state.companyInfo.description: ""}
                     </Card.Text>
                     {
                        this.state.companyInfo ?
                           <Card.Text className="pb-0 theme-font basic-text">
                              <img src={this.state.companyInfo.address ? pin : ""} className="pr-2" style={{ width: 30 }} />
                              {this.state.companyInfo.address ? this.state.companyInfo.address : ""}
                           </Card.Text>
                        :
                        ""
                     }
                     {
                        this.state.companyInfo ?
                           <Card.Text className="pb-0 theme-font basic-text">
                              <img src={this.state.companyInfo.contact_details ? phonecall : ""} className="pr-2" style={{ width: 26 }} />
                              {this.state.companyInfo.contact_details ? this.state.companyInfo.contact_details : ""}
                           </Card.Text>
                        : ""
                     }
                     {
                        this.state.companyInfo.website ?
                           <Card.Text className="pb-0 theme-font basic-text">
                              <img src={this.state.companyInfo.website ? url : ""} className="pr-2" style={{ width: 26 }} />
                              <a href={this.state.companyInfo.website} target="new" style={{ fontSize: 14 }}>{this.state.companyInfo.website ? this.state.companyInfo.website : ""}</a>
                           </Card.Text>
                        : null
                     }
                     

                  </Card.Body>
               </Card>

            </Col>

         </>
      )
   }

}
export default Bussiness;