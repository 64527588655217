import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Common from '../hoc/Common.hoc'
import CompanyService from "../services/Domain.service";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import $ from 'jquery'

class GenerateKey extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShow: true,
      privateKey: this.props.hoc.getGenerateKey(),
      copySuccess: "",
      _id: this.props._id,
      productShow: false
    }
    this.saveGenerateKey = this.saveGenerateKey.bind(this);
  };

  static getDerivedStateFromProps(props, state) {

    return {
      isModalShow: props.isModalShow
    };

  }

  componentDidMount = () => {
    $(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({isModalShow: false})
			}
			return true;
		})
  }

  handleText = (text, name) => {
    this.setState({ [name]: text })
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
  }

  handleRdio = (value) => {
    this.setState({productShow: value});
  }

  saveGenerateKey() {
    const data = {
      loginKey: this.state.privateKey,
      _id: this.state._id,
      productShow: this.state.productShow
    };
    CompanyService.sendGenerateKey(data).then(async (data) => {
      if (data && data['success']) {
        await this.props.closeModal();
        await this.props.hoc.promiseAlert(data['message'], "success")
        this.props.changeGenerateKeyText();
        this.props.hoc.updateUserInRedux(data['data']);
        this.props.hoc.updateToken(this.props._id);
      } else {
        await this.props.hoc.promiseAlert(data['message'])
      }
    }).catch(async (err) => {
      await this.props.hoc.promiseAlert("Internal Server Error!")
    })
  }

  render() {

    return (

      <>

        <Modal show={this.state.isModalShow} >
          <Modal.Header >
            <Modal.Title>Product access private key. <br />
              It must be entered by users attempting to access business store when library is selected as "Private".</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >



              <Form.Group controlId="formBasicEmail">

                <Form.Control type="text" placeholder="Access key" value={this.state.privateKey} onChange={(ref) => this.handleText(ref.target.value, "privateKey")} ref={(textarea) => this.textArea = textarea} />
                {<OverlayTrigger
                  key={1}
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip-${1}`}>
                      Copy Acess Key
                  </Tooltip>
                  }
                >

                  <a href="#" onClick={this.copyToClipboard} className="copy-button p-2"><img src={require("../resources/img/copy.png")} width={20} height={20} alt="Axenomic"/></a>

                </OverlayTrigger>}
              </Form.Group>
              {/* <Col sm={6} className="float-left pl-0"> */}
                <Button className=" btn btn-primary theme-button theme-button2 width100 mb-2" type="button" onClick={() => { this.setState({ privateKey: this.props.hoc.getGenerateKey() }) }}>Reset</Button>
              {/* </Col> */}
              {/* <Col sm={6} className="float-left pr-0"> */}
                <Button className=" btn btn-primary theme-button theme-button2 width100 mb-2" onClick={this.saveGenerateKey}>Submit</Button>
              {/* </Col> */}
             
              
              <Form.Check
                type="checkbox"
                label="Make supply library private"
                name="publicPrivateProduct"
                id="formHorizontalRadios1"
                className="pt-3 mb-2"
                onClick={() => {this.handleRdio(this.state.productShow ? false : true)}}
                defaultChecked={true}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="theme-button" onClick={() => { this.props.closeModal() }}>
              Close
                </Button>

          </Modal.Footer>
        </Modal>
      </>

    )
  }

}
export default Common(GenerateKey);