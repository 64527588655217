import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router";

class About extends React.Component {
	constructor(props) {
      super(props);
      
      this.state = {
         data: 0
      }
   };



   render() {
      return (
         <div className="notfound" style={{minHeight: window.innerHeight - 80 + 'px'}}>
            
         </div>
      )
   }

}
export default withRouter(About);