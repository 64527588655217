import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { connect } from 'react-redux'
import Common from '../hoc/Common.hoc'
import LogoutModal from '../component/logoutmodal'
import $ from 'jquery'
import logo from "../resources/img/logo2.png";
import topcart from "../resources/img/topcart.png";
import CartAction from '../store/actions/Cart.action'

import {
  Link
} from "react-router-dom";


class Topnav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: 0,
      isLogout: false,
    }
  };

  closeModal() {
    this.setState({ "isLogout": false })
  }
  logout() {
    this.props.hoc.logOut();
    this.closeModal()
  }
  componentDidMount = () => {
    this.props.save_cart_in_onload();
    $(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({isLogout: false})
			}
			return true;
		})
  }

  render() {
    return (
      <>
        <Navbar collapseOnSelect expand="lg" className="nav-background pt-0 pb-0" variant="dark" >
          <Container className="pb-0 pt-2">

            <Navbar.Brand as={Link} to="/" className="text-white theme-font">
              <img
                src={logo}
                width="130"
                //height="30"
                className="d-inline-block align-top mr-2"
                alt="Axenomic logo"
              />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              {
                !this.props.isLoggedIn ?
                  <Nav defaultActiveKey="/home" as="ul" >

                    <Nav.Item as="li" className="pt-4 pb-2 ">
                      <Link to="/" className="nav-link text-white custom-font-nav pr-3">Home</Link>
                    </Nav.Item>

                    <div className="dropdown">
                      <Nav.Item as="li" className="pt-4 pb-5 pr-2 dropbtn">
                        <Nav.Link href="#" className="text-white custom-font-nav pr-4 pl-3">Why Axenomic <span className="arrow">⌄</span></Nav.Link>
                      </Nav.Item>

                      <div className="dropdown-content pb-2">
                        <Nav.Link href="/#about-us" className="nav-link text-color custom-font-nav no-border pl-3 pt-2 pb-2">How this got started</Nav.Link>
                        <Nav.Link href="/#whatAx" className="nav-link text-color custom-font-nav no-border pl-3 pt-2 pb-2">What is Axenomic</Nav.Link>
                        <Nav.Link href="/#howAx" className="nav-link text-color custom-font-nav no-border pl-3 pt-2 pb-2">How Axenomic works</Nav.Link>
                      </div>
                    </div>

                    <Nav.Item as="li" className="pt-4 pb-2 ">
                      <Link to="/faq" className="nav-link text-white custom-font-nav pr-3 no-border">FAQs</Link>
                    </Nav.Item>

                    <div className="nav-over mb-2">
                      <Nav.Item as="li">
                        <OverlayTrigger
                          key={1}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-${1}`}>
                              Browse your business's custom supply catalogue.
                          </Tooltip>
                          }
                        >
                          <Link to="/stores" className=" btn btn-primary theme-button-blue p-2 pl-3 pr-3 mr-2">Shop</Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key={2}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-${2}`}>
                              Manage your business's profile and catalogue.
                          </Tooltip>
                          }
                        >
                          <Link to="/signin" className=" btn btn-primary theme-button-blue p-2 pl-3 pr-3">Manage</Link>
                        </OverlayTrigger>
                      </Nav.Item>

                      <span className="block">Product Libraries</span>
                    </div>
                    <div className="nav-over-green ml-2 mb-2">
                      <Nav.Item as="li">
                        <OverlayTrigger
                          key={3}
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-${3}`}>
                              Add your business to Axenomic and simplify your buying process!
                          </Tooltip>
                          }
                        >
                          <Link to="/signup" className=" btn btn-primary theme-button3 p-2 pl-3 pr-3">Get Started</Link>
                        </OverlayTrigger>
                      </Nav.Item>
                      <span className="block">New user</span>
                    </div>
                    <Nav.Item as="li" className="pt-4 pb-2 cartLi">
                        <Link to="/employeecart" className="nav-link text-white custom-font-nav pr-3 no-border cartlink">
                            <img
                                src={topcart}
                                width="30"
                                //height="30"
                                className="d-inline-block align-top mr-2"
                                alt="Axenomic logo"
                              /> 
                              <span className="cartcount">{this.props.cartCount ? this.props.cartCount : this.props.cartCount < 0 ? 0 : 0}</span>
                             {/* <span className="carttext">Your Cart</span>*/}
                            </Link>
                    </Nav.Item>

                  </Nav> :
                  <Nav defaultActiveKey="/home" as="ul" >
                    {
                      this.props.companyInfo['isPreDashboard'] ? 
                        <div className="nav-over-green ml-2 mb-2" >
                          <Nav.Item as="li" >
                            <OverlayTrigger
                              key={3}
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-${3}`}>
                                  Request for products to be added in the Supply Library
                              </Tooltip>
                              }
                            >
                              <Link to="/newrequest" className=" btn btn-primary theme-button3 p-2 pl-3 pr-3" >New Product Requests</Link>
                            </OverlayTrigger>
                          </Nav.Item>
                          <span className="block" style={{ textAlign: 'center' }}>Notifications</span>
                        
                        </div>
                      : null
                    }
                    <div className="nav-over-green ml-2 mb-2" >
                      <Nav.Item as="li" >
                        <Link to="#" onClick={() => { this.setState({ isLogout: true }) }} className=" btn btn-primary theme-button3 p-2 pl-3 pr-3" >Log Out</Link>
                      </Nav.Item>
                      <span className="block" style={{ textAlign: 'center' }}>{this.props.companyInfo ? this.props.companyInfo['user_name'] ? this.props.companyInfo['user_name'] : "User" : "User "}</span>
                    </div>
                    
                  </Nav>
              }

            </Navbar.Collapse>
          </Container>

        </Navbar>

        <LogoutModal
          isModalShow={this.state.isLogout}
          closeModal={() => { this.closeModal() }}
          logout={() => { this.logout() }}
        />

      </>
    )
  }

}
let mapDispatchToProps = (dispatch) => {
  return {
    // _login: (data) => dispatch(Auth.getLoginAction(data)),
    save_cart_in_onload: () => dispatch(CartAction.onLoadCartSave())
  }
}
let mapStateToProps = ({ authReducer, cartReducer }) => {
  let { isLoggedIn, companyInfo } = authReducer;
  let { temp_cart, cartCount } = cartReducer
  return {
    isLoggedIn,
    companyInfo,
    temp_cart,
    cartCount
  }
}
export default Common(connect(mapStateToProps, mapDispatchToProps)(Topnav));
