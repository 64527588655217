import { createStore, combineReducers } from "redux";

//reducers
import AuthReducer from './reducers/Auth.reducer'
import CartReducer from './reducers/Cart.reducer'
import EmployeeAuthReducer from "./reducers/EmployeeAuth.reducer";

const rootReducer = combineReducers({
  authReducer: AuthReducer,
  EmployeeAuthReducer: EmployeeAuthReducer,
  cartReducer: CartReducer
});
export default createStore(rootReducer)