class AuthAction {
    getLoginAction = (data) => {
        return { type: 'USER_LOGIN', payload: data }
    }
    getTextAction = (data) => {
        return { type: 'TEST', payload: data }
    }
    logOut = (data) => {
        return { type: 'ADMIN_LOGUT', payload: data }
    }
    getEmployeeLogin = (data) => {
        return {type: "EMPLOYEE", payload: data}
    }
}

let Auth = new AuthAction()
export default Auth