class CartAction {
    getTemporaryCartAction = (data) => {
        return { type: 'MANIPULATE_CART', payload: data }
    }

    cartSaveCount = (quantity) => {
        return { type: "ADD_COUNT", payload: quantity ? quantity : 1 }
    }

    cartRemoveCount = (quantity) => {
        return { type: "SUB_COUNT", payload: quantity ? quantity : 1 }
    }

    onLoadCartSave = () => {
        return { type: "SAVE_CART_COUNT", payload: localStorage.getItem("cartCount") }
    }

    removeCrat = (data) => {
        return {type: "REMOVE", payload: data};
    }

    removeAllCart = () => {
        return {type: "REMOVE_ALL", payload: null};
    }
}

export default new CartAction()