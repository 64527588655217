import BaseService from './Base.service.js'
import { serviceUrl, businessSlug } from '../config/Credential.config'

class DomainService extends BaseService {
    constructor(props) {
        super(props)
    }

    getCompany = (_domain, queryParams = undefined) => {
        this.serviceUrl = (queryParams == undefined) ? serviceUrl+businessSlug+'/'+_domain : serviceUrl+businessSlug+'/'+_domain+"?employee="+queryParams;
        return this.get().then((response) => response.json())
    }

    sendGenerateKey = (data) => {
        this.serviceUrl = serviceUrl+businessSlug+'/generate-key'
        return this.post(data, true).then((response) => response.json())
    }
}

export default new DomainService();