import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ProductService from '../services/Product.service';
import $ from 'jquery'

class NewRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShow: true,
      product_url: "",
      email: "",
      company_secret: "",
      validator: {
        email: null,
        company_secret: null,
        product_url: null
      }
    }
    this.copyState = this.state
    this.copyValidator = this.state.validator
  };

  static getDerivedStateFromProps(props, state) {

    return {
      isModalShow: props.isModalShow
    };

  }
  componentDidMount = () => {
    $(document).on('keydown', (evt) => {
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 27) {
        this.setState({ isModalShow: false })
      }
      return true;
    })
  }

  enterPressed = (event) => {
    var code = event.keyCode;
    if (code === 13) {
      event.preventDefault()//13 is the enter keycode
      this.submit(event)
    }
    else {
      return true
    }
  }

  submit = (e) => {
    e.preventDefault()
    if (this.state.email.trim() == "")
      this.setState({ validator: { email: "Email is required *" } })
    else if (!this.props.hoc.verifyEmail(this.state.email.trim()))
      this.setState({ validator: { email: "Please enter a valid Email *" } })
    else if (this.state.product_url.trim() == "")
      this.setState({ validator: { product_url: "Product URL is required *" } })
    else if (!this.state.product_url.split("/")[5])
      this.setState({ validator: { product_url: "Please enter a valid URL *" } })
    else if (this.state.company_secret.trim() == "")
      this.setState({ validator: { company_secret: "Company secret key is required *" } })
    else {
      let item = {
        email: this.state.email.trim(),
        product_url: this.state.product_url.trim(),
        company_secret: this.state.company_secret.trim(),
        company_id: this.props.company_id
      }
      ProductService.saveNewReq(item).then( async data => {
        if(data && data.success) {
          await this.props.hoc.promiseAlert('Product is requested successfully', "success")
          // this.setState({isModalShow: false})
          this.setState({product_url: "", email: "", company_secret: ""});
          this.props.closeModal()
        }
        else {
          this.props.hoc.promiseAlert(data.message ? data.message : "Something went wrong", "error")
        }
      }).catch(err => {
        this.props.hoc.promiseAlert("Something went wrong", "error")
      })
    }
  }

  handleText = (text, name) => {
    this.setState({ [name]: text, validator: this.copyValidator })
  }

  render() {

    return (

      <>

        <Modal show={this.state.isModalShow} >
          <Modal.Header >
            <Modal.Title>New Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >

              {/* <Form.Group controlId="formBasicEmail">
                <Form.Label>Full name</Form.Label>
                <Form.Control type="text" placeholder="Full name" onChange={(ref) => this.handleText(ref.target.value, "email")} value={this.state.full} onKeyDown={this.enterPressed} />

              </Form.Group> */}

              <Form.Group controlId="formBasicEmail">
                <Form.Label>*Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={(ref) => this.handleText(ref.target.value, "email")} value={this.state.email} onKeyDown={this.enterPressed} />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                      </Form.Text>
                {this.state.validator.email && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.email}</span>}
              </Form.Group>

              <Form.Group controlId="formBasicText">
                <Form.Label>*Web address for product</Form.Label>
                <Form.Control type="text" placeholder="Web address for product" onChange={(ref) => this.handleText(ref.target.value, "product_url")} value={this.state.product_url} onKeyDown={this.enterPressed} />
                {this.state.validator.product_url && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.product_url}</span>}
              </Form.Group>

              <Form.Group controlId="formBasicText">
                <Form.Label>*Company secret key</Form.Label>
                <Form.Control type="text" placeholder="Company secret key" onChange={(ref) => this.handleText(ref.target.value, "company_secret")} value={this.state.company_secret} onKeyDown={this.enterPressed} />
                {this.state.validator.company_secret && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.company_secret}</span>}
              </Form.Group>


              <Button className=" btn btn-primary theme-button theme-button2 width100 mb-2" onClick={this.submit} >Submit</Button>


            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="theme-button" onClick={async () => {
              this.setState(this.copyState)
              this.props.closeModal()
            }}>
              Close
                </Button>

          </Modal.Footer>
        </Modal>
      </>

    )
  }

}
export default NewRequest;