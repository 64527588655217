import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Link, browserHistory, IndexRoute, useHistory } from 'react-router'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Common from '../hoc/Common.hoc'
import GenerateKey from './generateKey';
import {serviceUrl} from "../config/Credential.config"
import $ from 'jquery'
import CompanyService from '../services/Company.service';
import Modal from 'react-bootstrap/Modal';
import url from "../resources/img/url.png";
import phonecall from "../resources/img/phone-call.png";
import pin from "../resources/img/pin.png";
import default_images from "../resources/img/images.png"

class BussinessProfileComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: 0,
         modalShow: false,
         compnayInfo: this.props.companyInfo,
         _id: this.props.companyInfo._id ? this.props.companyInfo._id : "",
         copySuccess: "",
         mrTop:0,
         isProfileEdit:false,
         showGenerate: this.props.companyInfo.loginKey ? false : true,
         business_name: this.props.companyInfo.business_name ? this.props.companyInfo.business_name : "",
         business_tagline: this.props.companyInfo.business_tagline ? this.props.companyInfo.business_tagline : "",
         contact_details: this.props.companyInfo.contact_details ? this.props.companyInfo.contact_details : "",
         business_logo: this.props.companyInfo.business_logo ? this.props.companyInfo.business_logo : this.props.companyInfo.business_logo,
         description: this.props.companyInfo.description ? this.props.companyInfo.description : "",
         address: this.props.companyInfo.address ? this.props.companyInfo.address : "",
         tempImgUrl: "",
         website: this.props.companyInfo.website ? this.props.companyInfo.website : "",
         validator: {
            business_name: null,
            contact_details: null,
            business_logo: null,
            website: null,
            description: null,
            address: null,
            business_tagline: null
         },
         selectedFile: "",
         months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      }
      this.copyValidator = this.state.validator;
      this.generateKey = this.generateKey.bind(this);
   };

   componentDidMount() {
      $(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({modalShow: false})
			}
			return true;
		})
   }
   shouldComponentUpdate(newProps, newState) {
      return true;
   }
   componentDidUpdate(prevProps, prevState) {
      //
   }
   componentWillUnmount() {
      //
   }

   generateKey () {

      this.setState({ "modalShow": true })
   }

   closeModal() {
      this.setState({ "modalShow": false })
   }

   changeGenerateKeyText () {
      this.setState({"showGenerate": false});
   }

   setMargin(id){
      var elmnt = document.getElementById(id);
      var elmnt2 = document.getElementById("parentHeight");
      
      var mrTop = (parseFloat(elmnt2.offsetHeight) - parseFloat(elmnt.offsetHeight)) / 2;

      if(mrTop > 0){
         this.setState({mrTop:mrTop})
      }
    
   }

   handleText = (text, name) => {
      this.setState({ [name]: text, validator: this.copyValidator })
   }

   editProfileAction = () => {
      this.setState({isProfileEdit:true});
   }

   handleFile = (event, name) => {
      event.preventDefault()
      var file = this.refs.file.files[0];
      if (file) {
         let reader = new FileReader();
         let url = reader.readAsDataURL(file);

         if (file.size > 2000000000) {
           this.setState({ validator: { business_logo: "Image size should be less than 20mb" } })
         } else {
            if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg") {
               reader.onloadend = function (e) {
                  this.setState({
                  tempImgUrl: [reader.result], selectedFile: file, [name]: file.name
                  });
               }.bind(this);
            } else {
               this.setState({ validator: { business_logo: "Only .png, .jpg and .jpeg format allowed!" } })
            }
         }
      }
   }


   editProfile = () => {
      const { business_name, contact_details, website, description, address, business_tagline } = this.state;
      if (business_name.trim() == "") {
         this.setState({ validator: { business_name: "Business name is required *" } })
      } else if (description.length > 250) {
         this.setState({ validator: { description: "Description length not more then 250" } })
      } else if (address.length > 100) {
         this.setState({ validator: { address: "Address length not more then 100" } })
      } else if (contact_details !== "" && contact_details.length > 10 ) {
         this.setState({ validator: { contact_details: "Contact details length not more then 10" } })
      } else if (contact_details !== "" && contact_details.length < 10 ) {
         this.setState({ validator: { contact_details: "Contact details length not less then 10" } })
      } else {
         let data = {
            business_tagline: this.state.business_tagline,
            contact_details: this.state.contact_details,
            description: this.state.description,
            address: this.state.address,
            website: this.state.website,
            _id: this.state._id
         }
         let formData = new FormData();
         formData.append(
           "business_logo",
           this.state.selectedFile,
         );
         for (let property in data) {
            formData.append(
              property,
              data[property],
            );
         }
         
         CompanyService.editProfile(formData).then(async res => {
            if (res && res.success) {
               this.setState({ compnayInfo: res.data })
               await this.props.hoc.promiseAlert(res.message, "success");
               await this.props.hoc.updateToken(res.data._id);
               await this.props.hoc.updateUserInRedux(res.data);
               this.setState({isProfileEdit:false});
            } else {
               await this.props.hoc.promiseAlert(res.message, "error");
            }
         }).catch(async err => {
            await this.props.hoc.promiseAlert("Internal Server Error", "error");
         })
      }
      
   }

   cancelEdit = () => {
      this.setState({tempImgUrl: "", isProfileEdit: false});
   }

   deleteCompany = () => {
      this.setState({delCompanyModal: false})
      let token = this.props.hoc.getLocalStorage("_token")
      if(token) {
         CompanyService.deleteCompany(token).then(async (data) => {
            if(data && data.success) {
               await this.props.hoc.promiseAlert("Company has been decativated and it will be automatically remove after three months if you don't login again", "success");
               this.props.hoc.logOut()
            }
            else {
               this.props.hoc.promiseAlert("Something went wrong", "error");
            }
         }).catch((err) => {
               this.props.hoc.promiseAlert(err.mesage ? err.message : "Something went wrong", "error");
         })
      }
   }

   getDateForDeleteAcount = () => {
      let date = new Date(new Date().setMonth(new Date().getMonth() + 3)).getDate();
      let months = this.state.months[(new Date(new Date().setMonth(new Date().getMonth() + 3)).getMonth())];
      let year = new Date(new Date().setMonth(new Date().getMonth() + 3)).getFullYear();

      return `${date} ${months} ${year}`;

   }

   render() {
      return (
         <>
         <Modal show={this.state.delCompanyModal}  >
					<Modal.Header >
                  <Modal.Title>Your account has been deactivated and it will be completely removed after three months from now. To reactivate your account please log in again by {this.getDateForDeleteAcount()} 23:59</Modal.Title>
					</Modal.Header>

					<Modal.Footer>
						<Button className=" btn btn-primary theme-button  pl-4 pr-4 mb-2" onClick={this.deleteCompany} >Yes</Button>
						<Button className=" btn btn-primary theme-button theme-button2  pl-4 pr-4 mb-2" onClick={() => this.setState({ delCompanyModal: false })}>No</Button>


					</Modal.Footer>
				</Modal>
            <Col xs={12} md={4} className="float-left" id="parentHeight">
               {
                  this.state.compnayInfo.business_logo ? <Figure className="col-sm-12 p-0 text-center mt-3" style={{marginTop:this.state.mrTop, width:"100%"}}>
                     <Figure.Image
                        width="80%"
                        height={180}
                        alt="171x180"
                        id={"img"+this.state.compnayInfo.business_logo}
                        onLoad={()=>{this.setMargin("img"+this.state.compnayInfo.business_logo)}}
                        src={ (this.state.tempImgUrl) ? this.state.tempImgUrl : serviceUrl+"company_logo/"+this.state.compnayInfo.business_logo}
                     />

                     </Figure>
                  : <Figure className="col-sm-12 p-0 text-center mt-3" style={{marginTop:this.state.mrTop, width:"100%"}}>
                     <Figure.Image
                        width="80%"
                        height={180}
                        alt="171x180"
                        id={"img"+default_images}
                        onLoad={()=>{this.setMargin("img"+default_images)}}
                        src={(this.state.tempImgUrl) ? this.state.tempImgUrl : default_images}
                     />
                  </Figure>
               }
               

            </Col>

            <Col xs={12} md={8} className="float-left">

            {(this.state.isProfileEdit)?

               <Card style={{ width: '100%' }} className="theme-yellow custom-card">
                  <Card.Body className="pr-0 edit-bussiness">
                      <div className="col-sm-8 p-0 float-left">
                       <Form.Group controlId="formBasicFile">
                           <InputGroup.Prepend>
                              <InputGroup.Text>Business Logo</InputGroup.Text>
                           </InputGroup.Prepend>
                         <Form.Control type="file" placeholder="Business name" ref="file" onChange={(e) => this.handleFile(e, "business_logo")} />
                         {this.state.validator.business_logo && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.business_logo}</span>}
                       </Form.Group>
                     </div>
                     <InputGroup className="mb-3">
                         <InputGroup.Prepend>
                           <InputGroup.Text>Business Name</InputGroup.Text>
                         </InputGroup.Prepend>
                         <FormControl aria-label="Business Name" id="company_name_edit" disabled={true} value={this.state.compnayInfo ? this.state.business_name : ""} onChange={(ref) => this.handleText(ref.target.value, "business_name")} />
                     </InputGroup>

                     <InputGroup className="mb-3">
                         <InputGroup.Prepend>
                           <InputGroup.Text>Business Tagline</InputGroup.Text>
                         </InputGroup.Prepend>
                         <FormControl aria-label="Business Tagline" id="company_tag_edit" value={this.state.compnayInfo ? this.state.business_tagline : ""} onChange={(ref) => this.handleText(ref.target.value, "business_tagline")}/>
                         {this.state.validator.business_tagline && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.business_tagline}</span>}
                     </InputGroup>

                     <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                           <InputGroup.Text>Business Description</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl as="textarea" id="company_desc_edit"  aria-label="Business Description" value={this.state.compnayInfo ? this.state.description: ""} onChange={(ref) => this.handleText(ref.target.value, "description")}/>
                        {this.state.validator.description && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.description}</span>}
                     </InputGroup>

                     <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                           <InputGroup.Text>Company Adress</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="Company Adress" id="company_adress_edit" value={this.state.compnayInfo ? this.state.address : ""} onChange={(ref) => this.handleText(ref.target.value, "address")}/>
                        {this.state.validator.address && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.address}</span>}
                     </InputGroup>

                     <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                           <InputGroup.Text>Company Contact</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl aria-label="Company Contact" id="company_contact_edit" value={this.state.compnayInfo ? this.state.contact_details : ""} onChange={(ref) => this.handleText(ref.target.value, "contact_details")}/>
                        {this.state.validator.contact_details && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.contact_details}</span>}
                     </InputGroup>

                     <InputGroup className="mb-3">
                         <InputGroup.Prepend>
                           <InputGroup.Text>Company Website</InputGroup.Text>
                         </InputGroup.Prepend>
                         <FormControl aria-label="Company Website" id="company_web_edit" value={this.state.compnayInfo ? this.state.website : ""} onChange={(ref) => this.handleText(ref.target.value, "website")}/>
                     </InputGroup>   
                     
                     <Card.Text className="pb-0 theme-font basic-text">
                        
                        <div className="float-right">
                           <Button variant="primary" className="theme-button button-bg mr-2" onClick={this.editProfile}>Save</Button>
                           <Button variant="primary" className="theme-button button-bg mr-2" onClick={this.cancelEdit}>Cancel</Button>
                           {/* <Button variant="primary" className="theme-button2 button-bg" type="button" onClick={this.generateKey}>{!this.state.showGenerate ? "Regenerate key": "Generate Key"}</Button> */}
                        </div>
                     </Card.Text>

                  </Card.Body>
               </Card>
               :
               <Card style={{ width: '100%' }} className="theme-yellow custom-card">
                  <Card.Body className="pr-0">
                  <Card.Title className="header pb-2 theme-font"><span className="span-bold">{this.state.compnayInfo ? this.state.compnayInfo.business_name : ""}</span></Card.Title>
                     <Card.Subtitle className="mb-2 pb-2 theme-font subheader"> {this.state.compnayInfo ? this.state.compnayInfo.business_tagline ? this.state.compnayInfo.business_tagline : "Please fill up business tagline..." : ""} </Card.Subtitle>
                     <Card.Text className="pb-0 theme-font basic-text">
                        {this.state.compnayInfo ? this.state.compnayInfo.description ? this.state.compnayInfo.description : "Please fill up business description..." : ""}
                        </Card.Text>
                     {
                        this.state.compnayInfo ?
                           <Card.Text className="pb-0 theme-font basic-text">
                              <img src={pin} className="pr-2" style={{ width: 30 }} />
                              {this.state.compnayInfo ? this.state.compnayInfo.address ? this.state.compnayInfo.address : "Please fill up business address..." : ""}
                           </Card.Text>
                        :
                        ""
                     }
                     
                     {
                        this.state.compnayInfo ?
                           <Card.Text className="pb-0 theme-font basic-text">
                              <img src={phonecall} className="pr-2" style={{ width: 26 }} />
                              {this.state.compnayInfo ? this.state.compnayInfo.contact_details ? this.state.compnayInfo.contact_details : "Please fill up business contact details..." : ""}
                           </Card.Text>
                        : ""
                     }
                     
                     <Card.Text className="pb-0 theme-font basic-text">
                        <img src={url} className="pr-2" style={{ width: 26 }} />
                        {
                           this.state.compnayInfo.website ? <a href={this.state.compnayInfo.website} target="new"   style={{ fontSize: 14 }}>
                           {this.state.compnayInfo.website}
                           </a> : "Please fill up business contact website..."
                        }
                        
                        <div className="float-right">
                        <br />
                           <Button variant="primary" className="theme-button button-bg mr-4" onClick={() => this.setState({ delCompanyModal: true })}>Delete Account</Button>
                           <Button variant="primary" className="theme-button button-bg mr-4" onClick={this.editProfileAction}>Edit Business Profile</Button>
                           <Button variant="primary" className="theme-button2 button-bg" type="button" onClick={this.generateKey}>{!this.state.showGenerate ? "Regenerate key": "Generate Key"}</Button>
                        </div>
                     </Card.Text>

                  </Card.Body>
               </Card>
            }

            </Col>
            <GenerateKey isModalShow={this.state.modalShow} _id={this.state.compnayInfo._id} closeModal={() => { this.closeModal() }} changeGenerateKeyText={() => {this.changeGenerateKeyText()}}/>

         </>
      )
   }

}
export default Common(BussinessProfileComponent);
