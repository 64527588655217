import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Base from '../pages/base'
import Auth from '../store/actions/Auth.action'
import { connect } from 'react-redux'
import AuthService from '../services/Auth.service'
import { withRouter } from "react-router";
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login'
import Loader from '../component/loader';
import Banner from '../component/banner';
import Common from '../hoc/Common.hoc'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import $ from 'jquery';
import facebook from "../resources/img/facebook.png";
import google from "../resources/img/google.png";

class BannerFrom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShow: false,
      marginTop: 0,
      marginTop2: 0,
      isLoader: false,
      user_name: "",
      email: "",
      password: "",
      confirm_password: "",
      business_name: "",
      validator: {
        email: null,
        user_name: null,
        password: null,
        confirm_password: null,
        business_name: null
      }
    }
    this.copyState = this.state
    this.copyValidator = this.state.validator
  };

  componentDidMount() {
    $(document).on('keydown', (evt) => {
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode == 27) {
        this.setState({ isModalShow: false })
      }
      return true;
    })
    let marginTop2 = (document.getElementById("containerHeight").clientHeight - document.getElementById("signUpFromContent").clientHeight - 100) / 2;
    let marginTop = (document.getElementById("containerHeight").clientHeight - document.getElementById("content").clientHeight - 100) / 2;

    if (marginTop > 0) {
      this.setState({ marginTop: marginTop })
    }

    if (marginTop2 > 0) {
      this.setState({ marginTop2: marginTop2 })
    }
  }

  handleText = (text, name) => {
    this.setState({ [name]: text, validator: this.copyValidator })
  }

  submit = () => {
    if (this.state.email.trim() == "")
      this.setState({ validator: { email: "Email is required *" } })
    else if (!Base.verifyEmail(this.state.email.trim()))
      this.setState({ validator: { email: "Please enter a valid Email *" } })
    else if (this.state.user_name == "")
      this.setState({ validator: { user_name: "User Name is required *" } })
    else if (this.state.business_name.trim() == "")
      this.setState({ validator: { business_name: "Business Name is required *" } })
    else if (this.state.password.trim() == "")
      this.setState({ validator: { password: "Password is required *" } })
    else if (this.state.confirm_password.trim() == "")
      this.setState({ validator: { confirm_password: "Confim Password is required *" } })
    else if (this.state.password != this.state.confirm_password)
      this.setState({ validator: { confirm_password: "Confirm Password doesn't match *" } })

    else {
      AuthService.signUp(this.state).then(async (data) => {
        if (data && data.success) {
          // await this.props._login(data.data)
          await this.props.hoc.promiseAlert("Registration successful. A verification link has been sent to your email. please confirm your email.", "success")
          this.props.history.push('/signin')
        } else {
          await this.props.hoc.promiseAlert(data.message, "error");
        }
      }).catch(async err => {
        await this.props.hoc.promiseAlert("Somthin went to wrong, please try again later", "error");

      })
    }
  }

  responseGoogle = (response) => {
    let formItem = {
      user_name: response.profileObj.givenName + response.profileObj.familyName,
      email: response.profileObj.email,
      access_token: response.accessToken,
      social_id: response.googleId,
      social_token_id: response.tokenId,
      loginType: "GOOGLE"
    }
    AuthService.socialLogin(formItem).then(async (res) => {
      if (res && res.success) {
        this.props._login(res['data']['user'])
        this.props.hoc.setLocalStorage('_token', res['data']['token'])
        await this.props.hoc.promiseAlert("Login successful", "success")
        if (!res['data']['user']['isPreDashboard']) {
          this.props.history.push(`/prelogin/${res['data']['user']['_id']}`)
        } else {
          this.props.hoc.setLocalStorage('_domain', res['data']['user']['domain'])
          this.props.history.push(`/${res['data']['user']['domain']}`)
        }
      }
      else {
        if (res.message && typeof res.message === 'string')
          await this.props.hoc.promiseAlert(res.message, "error")
        else
          await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
      }

    }).catch(async err => {
      if (err.message && typeof err.message === 'string')
        await this.props.hoc.promiseAlert(err.message, "error")
      else
        await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
    })
  }

  responseFacebook = (response) => {
    if (response) {
      let formItem = {
        user_name: response.name,
        email: response.email,
        access_token: response.accessToken,
        social_id: response.id,
        social_token_id: response.signedRequest,
        loginType: "FACEBOOK"
      }
      AuthService.socialLogin(formItem).then(async (res) => {
        if (res && res.success) {
          this.props._login(res['data']['user'])
          this.props.hoc.setLocalStorage('_token', res['data']['token'])
          await this.props.hoc.promiseAlert("Login successful", "success")
          if (!res['data']['user']['isPreDashboard']) {
            this.props.history.push(`/prelogin/${res['data']['user']['_id']}`)
          } else {
            this.props.hoc.setLocalStorage('_domain', res['data']['user']['domain'])
            this.props.history.push(`/${res['data']['user']['domain']}`)
          }
        }
        else {
          if (res.message && typeof res.message === 'string')
            await this.props.hoc.promiseAlert(res.message, "error")
          else
            await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
        }
      }).catch(async err => {
        if (err.message && typeof err.message === 'string')
          await this.props.hoc.promiseAlert(err.message, "error")
        else
          await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
      })
    }
  }

  responseGoogleErr = (err) => {
    // Base.alertMessage("Error!", "Somthing went wrong, please try again later");
  }

  render() {

    return (
      <>

        <Container fluid className="pt-4 pb-4 signin theme-yellow" id="containerHeight">
          <Container>
            <Row>
              <Col xs={12} md={7} className="p-0" >
                <Card style={{ width: '100%' }} className="theme-yellow custom-card">
                  <Card.Body className="pl-0" id="content">
                    <Card.Title className="header pb-2 theme-font" style={{ fontSize: 44, fontWeight: 900 }}> Disrupt Your Purchasing Process & Streamline Recurring Purchases for Free!</Card.Title>

                    <Card.Text className="pb-0 theme-font basic-text" style={{ fontSize: 20, lineHeight: "28px" }} >Cut wasted time to restock everyday business supplies. Axenomic remembers your supply needs, enabling you to focus on running our business!</Card.Text>

                  </Card.Body>
                </Card>
                <Banner
                  goToSignup={() => this.goToSignup()}
                />
              </Col>
              <Col xs={12} md={5} className="p-0">
                <div className="form-center" id="signUpFromContent">
                  <h3 className="header2 pb-4 pt-0 mb-2 theme-font text-center"><span className="span-bold">Sign Up</span></h3>
                  <Form >
                    <Form.Group controlId="formBasicName">

                      <Form.Control type="text" placeholder="Work Email" onChange={(ref) => this.handleText(ref.target.value, "email")} value={this.state.email} />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                      {this.state.validator.email && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.email}</span>}
                    </Form.Group>


                    <Form.Group controlId="formBasicEmail">

                      <Form.Control type="email" placeholder="Full Name" onChange={(ref) => this.handleText(ref.target.value, "user_name")} value={this.state.user_name} />
                      {this.state.validator.user_name && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.user_name}</span>}
                    </Form.Group>

                    <Form.Group controlId="formBasicOrg">

                      <Form.Control type="text" placeholder="Organisation name" onChange={(ref) => this.handleText(ref.target.value, "business_name")} value={this.state.business_name} />
                      {this.state.validator.business_name && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.business_name}</span>}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">

                      <Form.Control type="password" placeholder="Password" onChange={(ref) => this.handleText(ref.target.value, "password")} value={this.state.password} />
                      {this.state.validator.password && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.password}</span>}
                    </Form.Group>

                    <Form.Group controlId="formBasicConfirmPassword">

                      <Form.Control type="password" placeholder="Confirm password" onChange={(ref) => this.handleText(ref.target.value, "confirm_password")} value={this.state.confirm_password} />
                      {this.state.validator.confirm_password && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.confirm_password}</span>}
                    </Form.Group>

                    <Button onClick={this.submit} variant="primary" className="theme-button2 width100 mb-2" type="button">
                      Sign Up
                    </Button>


                    {/* <div className="seperator"><span className="border-long left-border-long"></span>or<span className="border-long right-border-long"></span></div> */}
                    {/* <FacebookLogin
                      appId="778406716059691"
                      autoLoad={false}
                      fields="name,email,picture"
                      cssClass="width100 mb-2 facebok pos-relative"
                      callback={this.responseFacebook}
                      textButton="Facebook"
                      style={{
                        paddingVertical: 30,

                      }}
                      icon={
                        <img
                          src={facebook}
                          width="20"
                          height="20"
                          className="d-inline-block align-top mr-2 social-img"
                          alt="Axenomic logo"
                        />
                      }
                    /> */}

                    {/* <GoogleLogin
                      clientId="487888271400-aqjfraali4d0inrjsjku3oaejb6q6t3p.apps.googleusercontent.com"
                      buttonText="Google"
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogleErr}
                      cookiePolicy={'single_host_origin'}
                      render={renderProps => (
                        <button variant="primary" className="width100 mb-2 google pos-relative" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                          <img
                            src={google}
                            width="20"
                            height="20"
                            className="d-inline-block align-top mr-2 social-img"
                            alt="Axenomic logo"
                          />
                          Google
                        </button>
                      )}
                    /> */}
                    <Form.Text className="terms-and-condition">
                      By signing up, you agree to Axenomic's <a href="/terms-of-services" target="_blank" >Terms of Service</a> and <a href="/privacy-policy" target="_blank" >Privacy Policy</a>.
                    </Form.Text>
                  </Form>
                </div>
              </Col>
            </Row>

          </Container>
        </Container>

        {this.state.isLoader ? <Loader /> : null}

      </>

    )
  }

}
let mapDispatchToProps = (dispatch) => {
  return {
    _login: (data) => dispatch(Auth.getLoginAction(data)),
  }
}
let mapStateToProps = ({ authReducer }) => {
  let { isLoggedIn, test } = authReducer
  return {
    isLoggedIn: isLoggedIn
  }
}

export default withRouter(Common(connect(mapStateToProps, mapDispatchToProps)(BannerFrom)));
