import React from 'react';
import Swal from 'sweetalert2';
import store from '../store/index.store';
import AuthAction from '../store/actions/Auth.action';
import DomainService from '../services/Domain.service';
import AuthService from "../services/Auth.service";
const jwt = require('jsonwebtoken');
const config = require('../config/Credential.config');
const CryptoJS = require("crypto-js");
const cookie = require('js-cookie')


function Root(OriginalComponent) {
    return class NewComponent extends React.Component {
        constructor(props) {
            super(props)
            this.checkLoggedIn()
        }

        checkLoggedIn = () => {
            let getToken = this.decodeToken()
            if (this.props.location && this.props.location.pathname && (this.props.location.pathname == '/signin' || this.props.location.pathname == '/stores' || this.props.location.pathname == '/signup' || this.props.location.pathname == '/' || this.props.location.pathname == "/faq" || this.props.location.pathname == "/bussiness" || this.props.location.pathname.startsWith('/verify-email') || this.props.location.pathname.startsWith('/employeecart') || this.props.location.pathname.startsWith('/forgot-password') || this.props.location.pathname.startsWith('/terms-of-services') || this.props.location.pathname.startsWith('/privacy-policy'))) {
                
                if (getToken.success) {
                    if (!store.getState().authReducer.isLoggedIn) {
                        store.dispatch(AuthAction.getLoginAction(getToken.decoded.data))
                    }
                    let getDomain = this.common.getLocalStorage("_domain");
                    if (getDomain) {
                        this.props.history.push('/'+getDomain);
                    } else {
                        this.common.logOut();
                    }
                    // 
                }
            }
            else {
                if (!getToken.success) {
                    // code for normal user domain page.. 

                    if (this.props.match && this.props.match.params && this.props.match.params.domain && this.props.match.params.domain != "") {
                        this.checkBusinessProfileForeEmployee(this.props.match.params.domain)
                    } else {
                        this.props.history.push('/signin')
                    }
                }
                else {
                    if (!store.getState().authReducer.isLoggedIn) {
                        store.dispatch(AuthAction.getLoginAction(getToken.decoded.data))
                    }
                    if (this.props.match && this.props.match.params && this.props.match.params.domain && this.props.match.params.domain != "") {
                        this.checkBusinessProfile(this.props.match.params.domain);
                    }
                }
            }
        }

        checkBusinessProfileForeEmployee = (domain) => {
            DomainService.getCompany(domain).then(async (data) => {
            	if (data && !data['success']) {
                    await this.props.history.push("/404");
            	}
            }).catch(async (err) => {
            	await this.common.promiseAlert(err)
            	this.common.logOut();
            });
        }

        checkBusinessProfile = (domain) => {
            DomainService.getCompany(domain).then(async (data) => {
            	if (data && data['success']) {
            		if (!this.domainVerification(data['data'])) {
                        await this.props.history.push("/404");
            		}
            	} else {
                    this.common.logOut(false);
                    await this.props.history.push("/404");
            	}
            }).catch(async (err) => {
            	await this.common.promiseAlert(err)
            	this.common.logOut();
            });
        }

        decodeToken = () => {
            let getFromLocalStorage= true
            let token = this.common.getLocalStorage('_token')
            if(!token) {
                token = this.common.getCookie('_rem')
                if(token)
                    getFromLocalStorage = false
            }
            if (token) {
                if(!getFromLocalStorage) {
                    this.common.setLocalStorage("_token", token)
                }
                try {
                    let decoded = jwt.verify(token, config.jwtSecret);
                    // const bytes = CryptoJS.AES.decrypt(decoded, config.CryptoSecret);
                    // const originalText = bytes.toString(CryptoJS.enc.Utf8);
                    return { success: true, decoded: decoded, toke: token }
                } catch (err) {
                    return { success: false, error: err }
                }
            }
            else {
                return { success: false, error: "INVALID_TOKEN_STRUCTURE" }
            }

        }

        domainVerification = (domain) => {
            const getDomainKey = this.common.getLocalStorage("_domain_key");
            if (getDomainKey) {
                const decrypt = CryptoJS.AES.decrypt(getDomainKey, config.CryptoSecret).toString(CryptoJS.enc.Utf8);

                const new_decrypt = domain ? CryptoJS.AES.decrypt(domain, config.CryptoSecret).toString(CryptoJS.enc.Utf8) : "";
                if (new_decrypt && decrypt == new_decrypt) {
                    return true;
                } else {
                    return false;
                }
            } else {
                this.common.setLocalStorage("_domain_key", domain);
                return true;
            }
        }


        common = {
            makeId: (length) => {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            promiseAlert: (message = null, title = "error", showCancelBtn = false) => { //error, warning, success
                return Swal.fire({
                    title: title == 'error' ? 'Error !' : 'Success !',
                    text: message ? message : 'no message',
                    icon: title,
                    showCancelButton: showCancelBtn,
                    confirmButtonText: 'OK',
                    cancelButtonText: 'No'
                })
            },
            verifyEmail: (email) => {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                    return true
                }
                else {
                    return false
                }
            },
            getLocalStorage: (key) => {
                return localStorage.getItem(key);
            },
            setLocalStorage: (key, value) => {
                localStorage.setItem(key, value);
            },
            setCookie: (key, value, exp = 10) => { // exp in days
                cookie.set(key, value, { expires: +exp })
            },
            getCookie: (key) => {
                return cookie.get(key)
            },
            deleteCookie: (key) => {
                cookie.remove(key)
            },
            cache: () => {
                localStorage.clear();
            },
            logOut: (redirect = true) => {
                this.common.cache();
                this.common.deleteCookie('_rem')
                store.dispatch(AuthAction.logOut(true))
                if (redirect)
                    this.props.history.push('/signin');
            },
            getGenerateKey: () => {
                return CryptoJS.AES.encrypt(config.CryptoText, config.CryptoSecret).toString();
            },
            updateToken: (_id) => {
                AuthService.updateToken({_id:_id}).then(res => {
                    if (res && res.success) {
                        this.common.setLocalStorage("_token", res.data);
                    } else {
                        this.common.promiseAlert(res.message);
                    }
                }).catch(err => {
                    this.common.promiseAlert(err);
                });
            }
        }

        render = () => {
            return <OriginalComponent {...this.props} hoc={this.common} />
        }
    }
}

export default Root
