

let initState = {
    companyInfo: {
        user_name: null
    },
    isLoggedIn: false,
    test: "ABCD"
}
let AuthReducer = (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'USER_LOGIN':
            return Object.assign({}, state, { companyInfo: payload, isLoggedIn: true })
        case 'TEST':
            return Object.assign({}, state, { test: payload });
        case 'ADMIN_LOGUT':
            return initState
        default:
            return state
    }
}

export default AuthReducer