import BaseService from './Base.service.js'
import { serviceUrl, authSlug } from '../config/Credential.config'

class authService extends BaseService {
    constructor(props) {
        super(props)
    }
    login = (data) => {
        this.serviceUrl = serviceUrl+authSlug+'login'
        return this.post(data, true).then((response) => response.json())
    }
    signUp = (data) => {
        this.serviceUrl = serviceUrl+authSlug+'signup'
        return this.post(data, true).then((response) => response.json())
    }
    socialLogin = (data) => {
        this.serviceUrl = serviceUrl+authSlug+'social-login'
        return this.post(data, true).then((response) => response.json())
    }
    verifyEmail = (email) => {
        this.serviceUrl = serviceUrl+authSlug+'verify-email/'+email
        return this.get().then((response) => response.json())
    }
    preDashboard = (data) => {
        this.serviceUrl = serviceUrl+authSlug+'pre-dashboard'
        return this.post(data, null, true).then((response) => response.json())
    }
    updateToken = (data) => {
        this.serviceUrl = serviceUrl+authSlug+'update-token'
        return this.post(data, true).then((response) => response.json())
    }
    forgotPassword = (data) => {
        this.serviceUrl = serviceUrl+authSlug+'forgot-password'
        return this.post(data, true).then((response) => response.json())
    }
}
let AuthService = new authService()

export default AuthService