import React from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: 0
    }
  };


  render() {

    return (

      <>
        <Col xs={12} md={6} className="float-left">
          <Card className="custom-card">
            <Card.Body>
              <Card.Text className="footer-text theme-font pb-0 mb-0"><span>Affilate Disclosure:</span> <br /> <span>Axenomic is user-supported. When you buy through links on our site, we may earn an affiliate commission. This commission is at no cost to you and enables Axenomic to be a free service. For our full affiliate link policy, see our <Link to="/faq">FAQ page.</Link></span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} className="float-left text-right">
          <Card className="custom-card">
            <Card.Body>
              <Card.Text className="footer-text theme-font pb-0 mb-0" >Email: <a href="#" >info@merrysolutions.com</a>
                <Card.Text className="footer-text theme-font pb-0 mb-0" style={{ "padding-top": "8px" }}>Merry Solutions Inc
                </Card.Text>
                <Card.Text className="footer-text theme-font pb-0 mb-0" style={{ "padding-top": "8px" }}> © 2022 Axenomic.com
                </Card.Text>

              </Card.Text>
            </Card.Body>
          </Card>
        </Col>



      </>

    )
  }

}
export default Footer;