import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import $ from 'jquery'

class PreLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShow: true
    }
  };

  static getDerivedStateFromProps(props, state) {

    return {
      isModalShow: props.isModalShow
    };

  }

  componentDidMount = () => {
    $(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({isModalShow: false})
			}
			return true;
		})
  }

  render() {

    return (

      <>

        <Modal 
              show={this.state.isModalShow} size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
        >
          <Modal.Header >
            <Modal.Title>Pre login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
              <div className="col-sm-4 p-0 float-left">
                <img
                  src={require("../resources/img/logo2.png")}
                  width="130"
                  //height="30"
                  className="d-inline-block align-top mr-2"
                  alt="Axenomic logo"
                />
              </div>
              <div className="col-sm-8 p-0 float-left">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Business name" />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Business tag line" />
                </Form.Group>

              </div>

              <Form.Group controlId="formBasicEmail">
                
                <Form.Control as="textarea" type="text" placeholder="Business description" />

              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                
                <Form.Control type="text" placeholder="Adress" />

              </Form.Group>

               <Form.Group controlId="formBasicEmail">
                
                <Form.Control type="text" placeholder="Contact number" />

              </Form.Group>

               <Form.Group controlId="formBasicEmail">
                
                <Form.Control type="text" placeholder="Business website" />

              </Form.Group>


              <Button className=" btn btn-primary theme-button theme-button2 width100 mb-2">Complete signup</Button>


            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="theme-button" onClick={() => { this.props.closeModal() }}>
              Close
                </Button>

          </Modal.Footer>
        </Modal>
      </>

    )
  }

}
export default PreLogin;