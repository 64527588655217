
const CryptoJS = require("crypto-js");

class HandleToken {
    constructor () {
        //
    }

    
}

let handleToken = new HandleToken();
export default handleToken;