import React from 'react';
import Button from 'react-bootstrap/Button';
//import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Auth from '../store/actions/Auth.action'
import Root from '../hoc/Root.hoc'
import AuthService from '../services/Auth.service';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import default_logo from "../resources/img/default-logo.png"

class PreLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalShow: true,
      business_name: this.props.companyInfo.business_name ? this.props.companyInfo.business_name : "",
      business_tagline: "",
      contact_details: "",
      business_logo: "",
      description: "",
      address: "",
      tempImgUrl: default_logo,
      website: "",
      validator: {
        business_name: null,
        contact_details: null,
        business_logo: null,
        website: null,
        description: null,
        address: null,
        business_tagline: null
      },
      _id: this.props.match.params._id,
      selectedFile: ""
    }
    this.copyState = this.state
    this.copyValidator = this.state.validator
  };

  handleText = (text, name) => {
    this.setState({ [name]: text, validator: this.copyValidator })
  }

  // static getDerivedStateFromProps(props, state) {

  //   return {
  //     isModalShow: props.isModalShow
  //   };

  // }

  handleFile = (event, name) => {
    event.preventDefault()
    var file = this.refs.file.files[0];
    if (file) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(file);

      if (file.size > 2000000000) {
        this.setState({ validator: { business_logo: "Image size should be less than 20mb" } })
      } else {
        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg") {
          reader.onloadend = function (e) {
            this.setState({
              tempImgUrl: [reader.result], selectedFile: file, [name]: file.name, validator: this.copyValidator
            })
          }.bind(this);
        } else {
          this.setState({ validator: { business_logo: "Only .png, .jpg and .jpeg format allowed!" } })
        }
      }
    }
  }

  validURL = (str) => {
    if (str.startsWith("http")) {
      var pattern = new RegExp('^(http?:\\/\\/)?'+ // https protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'// fragment locator
      ); 
      return !!pattern.test(str);
    } else if (str.startsWith("https")) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // https protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'// fragment locator
      ); 
      return !!pattern.test(str);
    }
    
  }

  completeSignUp = () => {
    // else if (website.trim() != "" && !this.validURL(website)) {
    //   this.setState({ validator: { website: "This is not valid website. Website should be start with http:// or https://" } })
    // }
    const { business_name, contact_details, website, description, address, business_tagline } = this.state;
    if (business_name.trim() == "") {
      this.setState({ validator: { business_name: "Business name is required *" } })
    } else if (description.length > 250) {
      this.setState({ validator: { description: "Description length not more then 250" } })
    } else if (address.length > 100) {
      this.setState({ validator: { address: "Address length not more then 100" } })
    } else if (contact_details.trim() !== "" && contact_details.length > 10 ) {
      this.setState({ validator: { contact_details: "Contact details length not more then 10" } })
    } else if (contact_details.trim() !== "" && contact_details.length < 10 ) {
      this.setState({ validator: { contact_details: "Contact details length not less then 10" } })
    } else {
      let formData = new FormData();
      formData.append(
        "business_logo",
        this.state.selectedFile,
      );
      let data = {
        business_name: this.state.business_name,
        business_tagline: this.state.business_tagline,
        contact_details: this.state.contact_details,
        description: this.state.description,
        address: this.state.address,
        website: this.state.website,
        _id: this.state._id
      }
      for (let property in data) {
        formData.append(
          property,
          data[property],
        );
      }
      AuthService.preDashboard(formData).then(async (res) => {
        if (res && res.success) {
          this.props._login(res.data['user']);
          await this.props.hoc.promiseAlert(res.message, "success")
          this.props.hoc.setLocalStorage('_token', res['data']['token'])
          this.props.hoc.setLocalStorage('_domain', res['data']['user']['domain'])
          this.props.history.push(`/${res['data']['user']['domain']}`)
        } else {
          if (res.message) {
            await this.props.hoc.promiseAlert(res.message, "error")
          } else {
            await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
          }
        }
      }).catch(async err => {
        if (err.message && typeof err.message === 'string')
          await this.props.hoc.promiseAlert(err.message, "error")
        else
          await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
      })
    }
  }

  render() {

    return (

      <>
        <Container fluid className="pt-4 pb-4 signin theme-yellow " style={{ minHeight: window.innerHeight - 80 + 'px' }}>
          <Container id="mainContainer">
            <Row>
              <Col xs={12} md={6} className="p-0" style={{ marginTop: this.state.marginTop }}>
                <Card style={{ width: '100%' }} className="theme-yellow custom-card" >
                  <Card.Body className="pl-0" id="content">
                    <Card.Title className="header pb-2 theme-font" style={{ fontSize: 44, fontWeight: 900 }}> Redefine purchasing essential items and simplify your business</Card.Title>

                    <Card.Text className="pb-0 theme-font basic-text" style={{ fontSize: 20, lineHeight: "28px" }}>Cut wasted time and fight decision fatigue to restock everyday
                    business supplies. Axenomic’s always free web service remembers
                    your business needs, so you can focus on your most important work.
                    </Card.Text>

                    <Card.Text className="pb-0 theme-font basic-text" style={{ fontSize: 20, lineHeight: "28px", fontWeight: 'bold' }}>
                      Please provide company details </Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} className="p-0">
                <Form className="form-prelogin">
                  <div className="col-sm-4 p-0 float-left" style={{ height: 137, overflow: 'hidden' }}>
                    <img
                      src={this.state.tempImgUrl}
                      width="130"
                      //height="30"
                      className="d-inline-block align-top mr-2"
                      alt="Axenomic logo"
                    />
                  </div>
                  <div className="col-sm-8 p-0 float-left">
                    <Form.Group controlId="formBasicFile">
                      <Form.Control type="file" placeholder="Business name" ref="file" onChange={(e) => this.handleFile(e, "business_logo")} />
                      {this.state.validator.business_logo && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.business_logo}</span>}
                    </Form.Group>
                  </div>
                  <div className="col-sm-8 p-0 float-left">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="*Business name" onChange={(ref) => this.handleText(ref.target.value, "business_name")} value={this.state.business_name} />
                      {this.state.validator.business_name && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.business_name}</span>}
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Business tag line" onChange={(ref) => this.handleText(ref.target.value, "business_tagline")} value={this.state.business_tagline} />
                      {this.state.validator.business_tagline && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.business_tagline}</span>}
                    </Form.Group>

                  </div>

                  <Form.Group controlId="formBasicEmail">

                    <Form.Control as="textarea" type="text" placeholder="Business description" onChange={(ref) => this.handleText(ref.target.value, "description")} value={this.state.description} />
                    {this.state.validator.description && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.description}</span>}

                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">

                    <Form.Control type="text" placeholder="Address" onChange={(ref) => this.handleText(ref.target.value, "address")} value={this.state.address} />
                    {this.state.validator.address && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.address}</span>}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">

                    <Form.Control type="number" placeholder="Contact number" onChange={(ref) => this.handleText(ref.target.value, "contact_details")} value={this.state.contact_details} />
                    {this.state.validator.contact_details && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.contact_details}</span>}
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Business website" onChange={(ref) => this.handleText(ref.target.value, "website")} value={this.state.website} />
                    {this.state.validator.website && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.website}</span>}
                  </Form.Group>


                  <Button className=" btn btn-primary theme-button theme-button2 width100 mb-2" type="button" onClick={this.completeSignUp}>Complete signup</Button>

                </Form>
              </Col>
            </Row>
          </Container>
        </Container>
      </>

    )
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    _login: (data) => dispatch(Auth.getLoginAction(data)),
  }
}
let mapStateToProps = ({ authReducer }) => {
  let { isLoggedIn, companyInfo } = authReducer
  return {
    isLoggedIn,
    companyInfo
  }
}

export default withRouter(Root(connect(mapStateToProps, mapDispatchToProps)(PreLoginPage)));