let config = Object.assign({})
const production = require('./Environment.config').production // require('./Environment.config').production

Object.defineProperties(config,{
    serviceUrl: {
        value: production ? 'https://api.axenomic.com/' : 'http://localhost:4000/',
        writable: false
    },
    redirectUrl: {
        value: production ? 'https://axenomic.com/' : 'http://localhost:3000/', 
        writable: false
    },
    authSlug: {
        value: 'auth/',
        writable: false
    },
    productSlug: {
        value: 'product/',
        writable: false
    },
    businessSlug: {
        value: "business/",
        writable: false
    },
    employeSlug: {
        value: "employe/",
        writable: false
    },
    jwtSecret: {
        value: "worldisfullofdevelopers",
        writable: false
    },
    CryptoSecret: {
        value: "secret",
        writable: false
    },
    dynamicRoute: {
        value: [],
        writable: true
    },
    CryptoText: {
        value: "WeLoveCode-TriSoft",
        writable: false
    },
    paapiCred: {
        value: {
            AccessKey: "AKIAIXP3FHYRHKMQNTAQ",
            SecretKey: "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",
            PartnerTag: "disruptivnati-20",
            PartnerType: "Associates"
        },
        writable: false
    }
})
Object.seal(config)

module.exports = config