

let initState = {
    temp_cart: [],
    cartCount: 0
}

let CartReducer = (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'MANIPULATE_CART':
            localStorage.setItem("temp_cart", JSON.stringify(payload))
            return Object.assign({}, state, { temp_cart: payload })
        case 'EMPTY_CART':
            localStorage.setItem("temp_cart", [])
            return Object.assign({}, state, { temp_cart: JSON.stringify([]) });
        case 'ADD_COUNT':
            let getCount = localStorage.getItem("cartCount")
            if (getCount) {
                getCount = +getCount + +payload;
                localStorage.setItem("cartCount", getCount)
                return Object.assign({}, state, { cartCount: getCount });
            } else {
                localStorage.setItem("cartCount", payload)
                return Object.assign({}, state, { cartCount: payload });
            }
        case 'SAVE_CART_COUNT': 
            return Object.assign({}, state, { cartCount: payload });
        case 'REMOVE':
            let cartData = localStorage.getItem("temp_cart") ? JSON.parse(localStorage.getItem("temp_cart")) : [];
            if (cartData.length) {
                cartData.splice(payload, 1);
                localStorage.setItem("temp_cart", JSON.stringify(cartData))
                return Object.assign({}, state, { temp_cart: payload })
            }
        case 'SUB_COUNT':
            let getCountCart = localStorage.getItem("cartCount")
            if (getCountCart) {
                getCountCart = +getCountCart - +payload;
                localStorage.setItem("cartCount", getCountCart)
                return Object.assign({}, state, { cartCount: getCountCart });
            }
        case 'REMOVE_ALL':
            localStorage.removeItem("temp_cart");
            localStorage.removeItem("cartCount"); 
            return Object.assign({}, state, { cartCount: 0, temp_cart: [] });
        default:
            return state
    }
}

export default CartReducer