import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CompanyService from "../services/Company.service";
import common from "../hoc/Common.hoc";
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import $ from 'jquery'

class BussinesKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalShow: true,
      key: "",
      validator: {
        key: null
      }
    }
    this.copyValidator = this.state.validator
  };

  static getDerivedStateFromProps(props, state) {

    return {
      isModalShow: props.isModalShow
    };

  }

  componentDidMount = () => {
    $(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({isModalShow: false})
			}
			return true;
		})
  }

  handleText = (text, name) => {
    this.setState({ [name]: text, validator: this.copyValidator })
  }

  login = () => {
    const data = {
      key: this.state.key,
      domain: this.props._domain
    }

    CompanyService.employeeLogin(data).then(async res => {
      if (res && res.success) {
        let text = this.props.loginMessage ? this.props.loginMessage : "Product access granted";
        await this.props.hoc.promiseAlert(text, "success");
        this.props.closeModal();
        if (this.props.accessProduct) {this.props.accessProduct();}
        if (this.props.submitPrivetKey) {this.props.submitPrivetKey();}
        this.props._login(res.data);
        this.props.hoc.setLocalStorage("_employeeToken", res.data);
      } else {
        await this.props.hoc.promiseAlert(res.message, "error");
      }
    }).catch(async err => {
      await this.props.hoc.promiseAlert("Somthing went to wrong. Please try again later...", "error");
    })
  }

  copyCode(e){
    e.preventDefault()
    /* Get the text field */
    var copyText = document.getElementById("acessKey");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

  render() {

    return (

      <>

        <Modal show={this.state.isModalShow} >
          <Modal.Header >
            <Modal.Title>Purchasing details protected. To view {this.props.hoc.getLocalStorage("business_name")} custom supply library , enter the {this.props.hoc.getLocalStorage("business_name")} business access key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >

              <Form.Group>

                <Form.Control type="text" placeholder="Access key" id="acessKey" onChange={(ref) => this.handleText(ref.target.value, "key")}/>

              </Form.Group>
              <Button className=" btn btn-primary theme-button theme-button2 width100 mb-2" type="button" onClick={this.login}>Submit</Button>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="theme-button" onClick={() => { this.props.closeModal() }}>
              Close
                </Button>

          </Modal.Footer>
        </Modal>
      </>

    )
  }

}
export default common(BussinesKey);