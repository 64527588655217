import React from "react";
import RouterComponent from "./component/router";

export default class App extends React.Component {
  render () {
    return (
      <RouterComponent />
    )
  }
}

