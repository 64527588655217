import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Banner from '../component/banner';
import BannerFrom from '../component/bannerfrom';
import QuickLink from '../component/quicklinks';
import Companies from '../component/company';
import Footer from '../component/footer2';
import Root from '../hoc/Root.hoc';
import { withRouter } from "react-router";
import CompanyService from '../services/Company.service';

class TermsOfServices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//
		}
	};

	goToSignup = () => {
	}

	componentDidMount() {
		//
	}
	shouldComponentUpdate(newProps, newState) {
		return true;
	}
	componentDidUpdate(prevProps, prevState) {
		//
	}
	componentWillUnmount() {
		//
	}

	render() {
		return (
			<>
				<Container fluid className="pt-4 pb-4" style={{ minHeight: '100px' }}>
					<Container>
						<Row>

							<Col xs={12} style={{textAlign: "justify"}}>
								<h3 className="header2 pb-2 pt-2 mb-2 theme-font"><span className="span-bold">Axenomic Privacy Policy</span></h3><br />
								<span>Protecting your private information is our priority. This Statement of Privacy applies to www.Axenomic.com and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Axenomic include www.Axenomic.com. The Axenomic website is an ecommerce site. By using the Axenomic website, you consent to the data practices described in this statement.</span>
							</Col>
							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 1. Collection of your Personal Information </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>In order to better provide you with products and services offered on our Site, Axenomic may collect personally identifiable information, such as your :</p>

											<ul>
												<li> Mailing Address  </li>
												<li> E-mail Address  </li>
												<li> Phone Number  </li>
												<li> Employer </li>
											</ul>

										<p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Axenomic's public message boards, this information may be collected and used by others.</p> 
	
										<p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 2. Use of your Personal Information </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>Axenomic collects and uses your personal information to operate its website(s) and deliver the services you have requested.</p>

										<p>Axenomic may also use your personally identifiable information to inform you of other products or services available from Axenomic and its affiliates.</p>

										<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
											<Card.Body>
												<Card.Title>1. Sharing Information with Third Parties </Card.Title>
												<Card.Text className="pl-3 mb-2">
													<p>Axenomic does not sell, rent or lease its customer lists to third parties. </p>
													<p>Axenomic may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Axenomic, and they are required to maintain the confidentiality of your information.</p>
													<p>Axenomic may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Axenomic or the site; (b) protect and defend the rights or property of Axenomic; and/or (c) act under exigent circumstances to protect the personal safety of users of Axenomic, or the public.</p>
												</Card.Text>		
											</Card.Body>
										</Card>
										
										<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
											<Card.Body>
												<Card.Title>2. Tracking User Behavior</Card.Title>
												<Card.Text className="pl-3 mb-2">
													Axenomic may keep track of the websites and pages our users visit within Axenomic, in order to determine what Axenomic services are the most popular. This data is used to deliver customized content and advertising within Axenomic to customers whose behavior indicates that they are interested in a particular subject area.
												</Card.Text>
											</Card.Body>
										</Card>

										<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
											<Card.Body>
												<Card.Title>4. Automatically Collected Information</Card.Title>
												<Card.Text className="pl-3 mb-2">
													Information about your computer hardware and software may be automatically collected by Axenomic. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Axenomic website.
												</Card.Text>
											</Card.Body>
										</Card>

										<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
											<Card.Body>
												<Card.Title>4. Links</Card.Title>
												<Card.Text className="pl-3 mb-2">
													This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. 
												</Card.Text>
											</Card.Body>
										</Card>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 3. Security of your Personal Information </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>Axenomic secures your personal information from unauthorized access, use, or disclosure. Axenomic uses the following methods for this purpose: </p>

											<ul>
												<li> SSL Protocol  </li>
											</ul>

										<p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
	
										<p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 4. Right to Deletion </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>

											<ul>
												<li> Delete your personal information from our records; and  </li>
												<li> Direct any service providers to delete your personal information from their records. </li>
											</ul>

										<p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p> 
	
											<ul>
												<li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</li>
												<li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
												<li>Debug to identify and repair errors that impair existing intended functionality;</li>
												<li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
												<li>Comply with the California Electronic Communications Privacy Act;</li>
												<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
												<li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
												<li>Comply with an existing legal obligation; or</li>
												<li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
											</ul>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 5. Children Under Thirteen </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>Axenomic does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 6. Disconnecting your Axenomic Account from Third Party Websites </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>You will be able to connect your Axenomic account to third party accounts. BY CONNECTING YOUR AXENOMIC ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Accounts may be disconnected to third party sites through the site connector on the third party site, E.G. Facebook, Google.</p>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 7. E-mail Communications </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>From time to time, Axenomic may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>

										<p>If you would like to stop receiving marketing or promotional communications via email from Axenomic, you may opt out of such communications by Customers may unsubscribe from emails by "replying STOP" or "clicking on the UNSUBSCRIBE button."</p>
									</Card.Text>
								</Card.Body>
							</Card>

							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Title> 8. External Data Storage Sites </Card.Title>

									<Card.Text className="pl-3 mb-2">
										<p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Row>
					</Container>
				</Container>

				<Container fluid className="pt-4 pb-4" style={{ minHeight: '100px' }}>
					<Container>
						<Row>

							<Col xs={12} style={{textAlign: "justify"}}>
								<h3 className="header2 pb-2 pt-2 mb-2 theme-font"><span className="span-bold">Axenomic Data Retention & Data Deletion Policy</span></h3>
							</Col>
							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Text className="pl-3 mb-2">
										<p>Axenomic.com and its simple features leverages your data to provide service and support to you and your business.  This service includes but is not limited to logging in and logging out, business information, and business related products selected and added to your Axenomic profile.  We will not share your data or utilize it in any medium not in affiliation with Axenomic.com.  Axenomic is part of the Merry Solutions Inc.</p>

										<p>We will not keep your personal data longer than we need to, how long this is depends on several factors, including,</p>

											<ul>
												<li> Why the data was collected  </li>
												<li> How old the data is  </li>
												<li> Whether there is a legal or regulatory reason to keep it  </li>
												<li> Whether the data is needed to protect you or Axenomic.com </li>
											</ul>

										<p>Data is stored on secure cloud servers, and you may access or change this information through updating your Axenomic user profile. If you would like to request the cancellation or deactivation of your account, you should contact our support team at info@axenomic.com for assistance. Cancellation or deactivation of your account does not ensure complete or comprehensive removal of the content or information you may have posted or otherwise made available publicly on the service while you were a registered user.  You should contact our support team at info@axenomic.com to request the deactivation of a profile you believe is fake or otherwise unauthorized.</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Row>
					</Container>
				</Container>

				<Container fluid className="pt-4 pb-4" style={{ minHeight: '100px' }}>
					<Container>
						<Row>

							<Col xs={12} style={{textAlign: "justify"}}>
								<h3 className="header2 pb-2 pt-2 mb-2 theme-font"><span className="span-bold">Changes to this Statement</span></h3>
							</Col>
							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Text className="pl-3 mb-2">
										<p>Axenomic reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Row>
					</Container>
				</Container>
				
				<Container fluid className="pt-4 pb-4" style={{ minHeight: '100px' }}>
					<Container>
						<Row>

							<Col xs={12} style={{textAlign: "justify"}}>
								<h3 className="header2 pb-2 pt-2 mb-2 theme-font"><span className="span-bold">Contact Information</span></h3>
							</Col>
							<Card className="col-md-12 col-xs-12 custom-card" style={{textAlign: "justify"}}>
								<Card.Body>
									<Card.Text className="pl-3 mb-2">
										<p>Axenomic welcomes your questions or comments regarding this Statement of Privacy. If you believe that Axenomic has not adhered to this Statement, please contact Axenomic at</p>

										<p><strong>Email Address:</strong> info@axenomic.com</p>

										<p><strong>Effective as of Feb 2, 2022 </strong></p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Row>
					</Container>
				</Container>
			</>
		)
	}
}
export default withRouter(Root(TermsOfServices));
