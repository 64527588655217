import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Root from '../hoc/Root.hoc';
import { withRouter } from "react-router";
import BussinessProfileComponent from '../component/bussinessprofilecomp';
import QuickLink from '../component/quicklinks';
import Footer from '../component/footer2';
import Loader from '../component/loader';
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Modal from 'react-bootstrap/Modal';
import ProductService from '../services/Product.service';
import CompanyService from '../services/Company.service';
import { connect } from 'react-redux';
import Auth from '../store/actions/Auth.action'
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import close from "../resources/img/close.png"
import leftarrow from "../resources/img/left-arrow.png";
import rightarrow from "../resources/img/right-arrow.png";
import list from "../resources/img/list.png";
import deleteIcon from "../resources/img/delete.png";
const renderTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Simple tooltip
	</Tooltip>
);

class BussinessProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			removeProdModal: false,
			removeCompanyProduct: false,
			productsToRemove: [],
			addModal: false,
			addBlock: false,
			modalLoader: false,
			add_searchKey: "",
			curretSearchPage: 1,
			amazonProducts: [],
			addProducts: [],
			amazonUrl: "",
			companyProducts: {
				company_details: {
					company_id: "",
					domain: ""
				},
				products: []
			},
			isNewRequest: false,
			isLoader: true,
			productView: 'public',
			removieShow: true,
			removeAllModal: false,
			rightArrow: true
		}
		this.addModalInitialPhase = {
			add_searchKey: "",
			curretSearchPage: 1,
			amazonProducts: [],
			addProducts: [],
			amazonUrl: "",
		}
		this.copyCompanProducts = this.state.companyProducts
		this.allCompanyProducts = []
	};

	goToSignup = () => {
		//
	}

	componentDidMount() {
		$(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({removeProdModal: false, addModal: false})
			}
			return true;
		})
		this.getProducts()
	}


	getProducts = () => {
		let domain = this.props.history.location ? this.props.history.location.pathname ? this.props.history.location.pathname.replace("/", "") : null : null
		if (domain) {
			ProductService.getProducts(domain).then(data => {
				if (data.success) {
					if (data.data.products && data.data.products.length) {
						this.setState({removieShow: true})
						
					} else {
						this.setState({removieShow: false})
					}
					this.setState({ companyProducts: data.data, isLoader: false })
					this.allCompanyProducts = data.data.products
					
				}
			}).catch(err => {
				//
			})
		}
	}

	initial() {
		//
	}

	shouldComponentUpdate(newProps, newState) {
		return true;
	}
	componentDidUpdate(prevProps, prevState) {
		//
	}
	componentWillUnmount() {
		//
	}
	closeModal() {
		this.setState({ "isNewRequest": false })
	}

	toggle(data) {
		let dt = data.target.checked ? 'public' : 'private';
		this.setState({ productView: dt, isLoader: true });
		const dataObject = {
			_id: this.props.companyInfo ? this.props.companyInfo._id : null,
			status: data.target.checked
		}
		CompanyService.privatePublicProduct(dataObject).then(res => {
			if (res && res.success) {
				this.setState({ isLoader: false });
				this.props.updateUser(res.data);
				this.props.hoc.updateToken(this.props.companyInfo._id);
			}
		}).catch(err => {
			this.setState({ isLoader: false });
			this.props.hoc.promiseAlert(err);

		})
	}

	submitSearch = (e) => {
		e.preventDefault()
		if (this.state.add_searchKey.trim() != "") {
			this.fetchSearch()
		}
	}

	removeProducts = async () => {
		this.setState({ removeProdModal: true });
	}

	fetchSearch = () => {
		this.setState({ modalLoader: true });
		ProductService.searchFromAmazon(this.state.add_searchKey.trim(), this.state.curretSearchPage).then(async (data) => {
			if (data && data['success']) {
				let products = data.data
				if (this.state.addProducts.length) {
					for (let i = 0; i < products.length; i++) {
						let selectedProduct = await this.state.addProducts.findIndex(add_p => add_p.ASIN === products[i].ASIN)
						if (selectedProduct != -1) {
							products[i]['isSelected'] = true
						}
					}
				}
				this.setState({ amazonProducts: products, modalLoader: false, rightArrow: true })
			} else {
				this.setState({ amazonProducts: this.state.amazonProducts, modalLoader: false, rightArrow: false })
				//   if (res.message && typeof res.message === 'string')
				// 	await this.props.hoc.promiseAlert(res.message, "error")
				//   else
				// 	await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
			}
		}).catch(async err => {
			this.setState({ amazonProducts: [], modalLoader: false })
			// if (err.message && typeof err.message === 'string')
			//   await this.props.hoc.promiseAlert(err.message, "error")
			// else
			//   await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
		})
	}

	submitAmazonUrl = (e) => {
		e.preventDefault()
		if (this.state.amazonUrl != "") {
			if (!this.state.amazonUrl.split("/")[5]) {
				this.props.hoc.promiseAlert("Invalid URL", "error")
			}
			else {
				this.setState({ modalLoader: true });
				ProductService.getAmazonProductFromUrl(this.state.amazonUrl).then(async (data) => {
					if (data && data['success']) {
						this.setState({ amazonProducts: data.data, modalLoader: false })
					}
					else {
						// this.props.hoc.promiseAlert('product currently not available', "error")
						if (data.data['Errors']) {
							this.props.hoc.promiseAlert('The item is not accessible through the product url', "error")
						}
						else {
							this.props.hoc.promiseAlert("Invalid url", "error")
						}
						this.setState({ modalLoader: false })
						//   if (res.message && typeof res.message === 'string')
						// 	await this.props.hoc.promiseAlert(res.message, "error")
						//   else
						// 	await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
					}
				}).catch(async err => {
					this.setState({ modalLoader: false })
					if (err.message && typeof err.message === 'string')
						await this.props.hoc.promiseAlert(err.message, "error")
					else
						await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
				})
			}

		}

	}

	addProductToCompany = (e) => {
		e.preventDefault()
		if (this.state.addProducts.length > 50) {
			this.props.hoc.promiseAlert(`You can add maximum 50 products at a time. but you have selected ${this.state.addProducts.length}`, "error")
		}
		else {
			let token = this.props.hoc.getLocalStorage("_token")
			if (this.state.addProducts.length && token) {
				ProductService.saveAmazonProducts(this.state.addProducts, token).then(async (data) => {
					if (data && data['success']) {
						this.setState({ ...this.state, ...this.addModalInitialPhase })
						this.getProducts()
						await this.props.hoc.promiseAlert("Products added successfully", "success");
						this.setState({ addModal: false });
					} else {
						this.props.hoc.promiseAlert("something went wrong", "error");
						//   if (res.message && typeof res.message === 'string')
						// 	await this.props.hoc.promiseAlert(res.message, "error")
						//   else
						// 	await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
					}
				}).catch(async err => {
					if (err.message && typeof err.message === 'string')
						await this.props.hoc.promiseAlert(err.message, "error")
					else
						await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
				})
			}
			else {
				this.props.hoc.promiseAlert("No product has been selected", "error");
			}
		}
	}

	productRemove = async (item, selected) => {
		if (selected) {
			this.setState({ ...this.state, productsToRemove: [...this.state.productsToRemove, item] })
			// this.state.productsToRemove.push(item)
		}
		else {
			// let removedProducts = this.state.productsToRemove.splice(this.state.productsToRemove.findIndex(p => p._id == item._id), 1)
			let products = await this.state.productsToRemove.filter(p => p._id != item._id)
			this.setState({ ...this.state, productsToRemove: products })
		}
	}

	deleteCompanyProducts = (e) => {
		e.preventDefault()
		this.setState({ removeProdModal: false })
		if (this.state.productsToRemove.length) {
			let token = this.props.hoc.getLocalStorage("_token")
			if (token) {
				ProductService.removeProducts(this.state.productsToRemove, token).then(data => {
					if (data && data.success) {
						this.setState({ removeCompanyProduct: false, productsToRemove: [], companyProducts: this.copyCompanProducts })
						this.allCompanyProducts = this.copyCompanProducts.products ? this.copyCompanProducts.products : []
						this.getProducts()
						this.props.hoc.promiseAlert("Products has been removed successfully", "success");
					}
					else {
						this.props.hoc.promiseAlert("something went wrong", "error");
					}
				})
			}
			else {
				this.props.hoc.promiseAlert("something went wrong", "error");
			}
		}
		else {
			this.props.hoc.promiseAlert("No product has been selected for remove", "error");
		}
	}

	searchProduct = async () => {
		if (this.state.searchKey != "") {
			let products = await this.allCompanyProducts.filter(p => p.DisplayValue.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) != -1)
			this.setState(
				{
					...this.state,
					companyProducts: {
						...this.state.companyProducts,
						products: products
					}
				}
			)
		}
		else {
			this.setState(
				{
					...this.state,
					companyProducts: {
						...this.state.companyProducts,
						products: this.allCompanyProducts
					}
				}
			)
		}
	}

	enterPressed = (event) => {
		var code = event.keyCode;
		if (code === 13) {
			event.preventDefault()//13 is the enter keycode
			this.submitSearch(event)
		}
		else {
			return true
		}
	}

	enterPressed1 = (event) => {
		var code = event.keyCode;
		if (code === 13) {
			event.preventDefault()//13 is the enter keycode
			this.submitAmazonUrl(event)
		}
		else {
			return true
		}
	}

	removeAll = () => {
		this.setState({removeAllModal: true});
	}

	removeSortListedProduct = async () => {
		let search_products = this.state.amazonProducts
		await search_products.map(p => {
			p['isSelected'] = false
		})
		this.setState({ addProducts: [], amazonProducts: search_products, removeAllModal: false })
	}

	toggleCartModal = (item) => {
		//
	}


	render() {

		let links = [];

		// this.state.quicklinksData.map((link, ind) => (
		// 	links.push(<QuickLink
		// 		image={link.image}
		// 		title={link.title}
		// 		desc={link.desc}
		// 		rating={link.rating}
		// 		key={ind}
		// 		price={link.price}
		// 		isNew={link.isNew}
		// 		gridWidth="col-md-3"
		// 		active={link.active != undefined ? link.active : true}
		// 	/>)
		// ))

		this.state.companyProducts.products.length &&
			this.state.companyProducts.products.map((link, ind) => {
				links.push(<QuickLink
					toggleCartModal={this.toggleCartModal}
					item={link}
					image={link.ImageURL}
					title={link.DisplayValue}
					isBuying={link.isBuying != undefined ? link.isBuying : false}
					// desc={link.desc}
					// rating={link.rating}
					key={link.ASIN}
					price={link.Amount}
					// isNew={link.isNew}
					gridWidth="col-md-3"
					active={link.active != undefined ? link.active : true}
					isRemove={this.state.removeCompanyProduct}
					removeFunc={this.productRemove}
				/>)
			})

		return (
			<>
				<Modal show={this.state.removeProdModal}  >
					<Modal.Header >
						<Modal.Title>Are you sure you want to remove these products you have selected ?</Modal.Title>
					</Modal.Header>

					<Modal.Footer>
						<Button className=" btn btn-primary theme-button  pl-4 pr-4 mb-2" onClick={this.deleteCompanyProducts} >Yes</Button>
						<Button className=" btn btn-primary theme-button theme-button2  pl-4 pr-4 mb-2" onClick={() => this.setState({ removeProdModal: false })}>No</Button>


					</Modal.Footer>
				</Modal>
				<Modal show={this.state.removeAllModal}  >
					<Modal.Header >
						<Modal.Title>Are you sure you want to remove these products you have selected ?</Modal.Title>
					</Modal.Header>

					<Modal.Footer>
						<Button className=" btn btn-primary theme-button  pl-4 pr-4 mb-2" onClick={this.removeSortListedProduct} >Yes</Button>
						<Button className=" btn btn-primary theme-button theme-button2  pl-4 pr-4 mb-2" onClick={() => this.setState({ removeAllModal: false })}>No</Button>


					</Modal.Footer>
				</Modal>
				<Modal show={this.state.addModal} size="lg" id="add_modal">
					{this.state.modalLoader ?
						<span className="product-loader">
							<Spinner className="spinner loader-img" variant="primary" animation="border" />
						</span>
						: null}
					<button className="modal-close" onClick={() => { this.setState({ addModal: false }) }}>
						<img
							src={close}
							width="15"
							height="15"

							alt="remove"
						/>
					</button>

					<div className="productAdd p-4 pt-5">

						<div className="form-group row">
							<input onKeyDown={this.enterPressed} placeholder="Search Product(s)" type="text" className="form-control col-sm-9 float-left ml-4" value={this.state.add_searchKey} onChange={(t) => this.setState({ add_searchKey: t.target.value })} />
							<button className=" btn btn-primary p-2 theme-button2 mb-2 col-sm-2 float-left ml-4" onClick={this.submitSearch} >Search</button>
						</div>
						<div className="form-group row">
							<input onKeyDown={this.enterPressed1} placeholder="Search Product(s) with Amazon URL" className="form-control col-sm-9 float-left ml-4" type="text" value={this.state.amazonUrl} onChange={(t) => this.setState({ amazonUrl: t.target.value })} />
							<button className=" btn btn-primary p-2 theme-button2 mb-2 col-sm-2 float-left ml-4" onClick={this.submitAmazonUrl} >Get Product</button>
						</div>
						{this.state.amazonProducts.length ? <p>Click on individual products to sort list them</p> : null}
						{
							this.state.amazonProducts.length ?
								<div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex' }}>

									{
										this.state.curretSearchPage > 1 &&
										<button onClick={async () => {
											await this.setState({ curretSearchPage: this.state.curretSearchPage - 1 })
											this.fetchSearch()
										}}
											className="arraw btn"><img
												src={leftarrow}
												width="30"
												height="30"

												alt="Axenomic logo"
											/></button>
									}

									{this.state.amazonProducts.map((item, index) => {
										return <label style={{ width: "10%" }} key={item['ASIN'] ? item['ASIN'] : index} className="products">
											<img src={item['ImageURL'] ? item['ImageURL'] : ""} style={{ height: 100, width: 100 }} />
											<p style={{ fontSize: 14, height: 61, overflow: "hidden" }} className="mb-1">{item['DisplayValue'] ? item['DisplayValue'] : "Unnamed"}</p>

											{/* <span className="stars-class">
												{this.state.starts.map((item2, index2) => {
													return <img
							                          src={require("../resources/img/star.png")}
							                          width="15"
							                          height="15"
							                          className=""
							                          alt="starts"
							                        />
												})}
												{this.state.nostarts.map((item2, index2) => {
													return <img
							                          src={require("../resources/img/star-blank.png")}
							                          width="15"
							                          height="15"
							                          className=""
							                          alt="starts"
							                        />
												})}
												</span> */}

												
											<p style={{ fontSize: 12, }}>{item['Amount'] ?"$"+ item['Amount'] : "$0.00"}</p>

											<p style={{ fontSize: 12, }}>
												<a href={item['DetailPageURL']} target="_blanck">Preview item</a> 
											</p>

											<input type="checkbox" checked={item.isSelected ? true : false} disabled={item.isSelected} value={item.isSelected ? true : false} onChange={async () => {
												item['isSelected'] = true
												let itemExists = await this.state.addProducts.findIndex(p => p.ASIN === item.ASIN)
												if (itemExists == -1)
													this.setState({ addProducts: [...this.state.addProducts, item] })
											}} />
										</label>
									})}
									{
										this.state.rightArrow && this.state.amazonProducts.length !== 1 &&
										<button onClick={async () => {
											await this.setState({ curretSearchPage: this.state.curretSearchPage + 1 })
											this.fetchSearch()
										}} className="arraw btn">
											<img
												src={rightarrow}
												width="30"
												height="30"

												alt="Axenomic logo"
											/></button>
									}
								</div>
								:
								<div className="block text-center">
									<img
										src={list}
										width="150"
										height="150"
										style={{ opacity: 0.05, marginTop: 30 }}
										alt="remove"
									/>
								</div>
						}
						{
							this.state.addProducts.length ?
								<div className="form-group row p-4" ref={this.state.addBlock}>
									<h4 className="width100 pb-2 m-2">Your sortlisted products</h4>
									{
										this.state.addProducts.map((item, index) => {
											return <span style={{ width: "10%" }} className="products mb-2 backlash" key={item['ASIN'] ? item['ASIN'] + Math.random(0, 999) : index + Math.random(0, 999)}>
												<img src={item['ImageURL'] ? item['ImageURL'] : ""} style={{ height: 100, width: 100 }} />
												<p style={{ fontSize: 14, height: 61, overflow: "hidden" }} className="mb-1">{item['DisplayValue'] ? item['DisplayValue'] : "Unnamed"}</p>

												{/* <span className="stars-class">
														{this.state.starts.map((item2, index2) => {
															return <img
									                          src={require("../resources/img/star.png")}
									                          width="15"
									                          height="15"
									                          className=""
									                          alt="starts"
									                        />
														})}
														{this.state.nostarts.map((item2, index2) => {
															return <img
									                          src={require("../resources/img/star-blank.png")}
									                          width="15"
									                          height="15"
									                          className=""
									                          alt="starts"
									                        />
														})}
														</span> */}

												<p style={{ fontSize: 12, width: 110 }}>Price: {item['Amount'] ? item['Amount'] : "0.00"} {item['Currency'] ? item['Currency'] : 'USD'} </p>
												<button onClick={async () => {
													let add_products = this.state.addProducts
													let search_products = this.state.amazonProducts
													await add_products.splice(add_products.findIndex(p => p.ASIN === item.ASIN), 1)
													await search_products.map(p => {
														if (p.ASIN === item.ASIN)
															p['isSelected'] = false
													})
													this.setState({ addProducts: add_products, amazonProducts: search_products })
												}}
													className="remove">
													<img
														src={deleteIcon}
														width="15"
														height="15"

														alt="remove"
													/></button>
											</span>
										})
									}
									{this.state.addProducts.length && <div className="width100"><button className=" btn btn-primary p-2 theme-button2 mb-2 col-sm-2 float-left mr-2" style={{ height: 50 }} onClick={this.addProductToCompany}>Add To Supply Library</button> <button className=" btn btn-primary p-2 theme-button mb-2 col-sm-2 float-left ml-2" style={{ height: 50 }} onClick={this.removeAll}>Remove All</button></div>}
									{this.state.addProducts.length && <div className="width100"></div>}
								</div> : null
						}


					</div>
				</Modal>

				<Container fluid className="pt-4 pb-4">
					<Container>
						<Row>

							<BussinessProfileComponent companyInfo={this.props.companyInfo} />

						</Row>
						<hr />
					</Container>
				</Container>

				<Container fluid className="pt-2 pb-4">
					<Container>
						<Row>
							<Col xs={12} md={6}>
								<h3 className="header2 pb-2 pt-2 mb-4 theme-font"><span className="span-bold">{this.props.companyInfo ? this.props.companyInfo.business_name : ""}</span> Custom Supply Library</h3>
							</Col>
							<Col xs={12} md={6} className="text-right">
								{!this.state.removeCompanyProduct ?
									<Button variant="primary" className=" theme-button2 mr-4 pl-4 pr-4 mb-2 p-2" type="submit" onClick={() => this.setState({ addModal: true })} >
										Add New
									</Button> : null
								}

								{
									this.state.removieShow ? <Button variant="primary" className=" theme-button mr-4 pl-4 pr-4 mb-2 p-2" type="submit" onClick={() => { this.setState({ removeCompanyProduct: !this.state.removeCompanyProduct }) }} >
									{this.state.removeCompanyProduct ? 'Cancel Remove' : 'Remove'}
									</Button> :null
								}
								

								{
									this.state.productsToRemove.length && this.state.removeCompanyProduct ?

										<Button variant="primary" className="theme-button mr-4 pl-4 pr-4 mb-2 p-2" onClick={() => this.removeProducts()}>Remove</Button>
										: null

								}

								{
									!this.state.removeCompanyProduct ?
										<OverlayTrigger
											placement="bottom"
											show={this.state.showMesage}
											//trigger="focus"
											//delay={{ show: 250, hide: 400 }}
											overlay={
												<Popover id={`popover-positioned-${1}`} >
													<Popover.Title as="h3" style={{ textTransform: "capitalize" }} className="theme-font">{this.state.productView}</Popover.Title>
													<Popover.Content className="theme-font">
														{this.state.productView == 'public'

															? <strong>Anyone may view product selections in business library</strong>
															: <strong>Only users with business access code may view product selections in business library</strong>
														}

													</Popover.Content>
												</Popover>
											}
										>
											<div className="productFilter"
												onMouseEnter={() => { this.setState({ showMesage: true }) }}
												onMouseLeave={() => { this.setState({ showMesage: false }) }}
											>
												<Toggle
													defaultChecked={this.props.companyInfo.productShow}
													icons={{ checked: <p className="filterKey theme-font">Public</p>, unchecked: <p className="filterKey theme-font">Private</p> }}
													onChange={(data) => { this.toggle(data) }}

												/>
											</div>
										</OverlayTrigger> : null
								}

							</Col>


						</Row>
						<Row>
							<Col xs={12} md={4} className="pt-0 mt-0 mb-4">
								<Form className="inline-search">
									<Form.Group controlId="formBasicEmail">

										<Form.Control type="text" placeholder="Search" onChange={async (e) => {
											e.preventDefault()
											await this.setState({ searchKey: e.target.value })
											this.searchProduct()
										}} value={this.state.searchKey} />

									</Form.Group>
									<Button variant="primary" onClick={this.searchProduct}>

									</Button>
								</Form>

							</Col>

						</Row>


						<Row className="min-height">
							{links.length
							?
								links
							:
								<Col className="text-center pb-5"> <p className="noitem "> No Products in Library to Display </p> </Col>
							}

						</Row>

					</Container>

				</Container>


				<Container fluid className="footer">
					<Container>
						<Row>

							<Footer />

						</Row>

					</Container>
				</Container>

				{this.state.isLoader ? <Loader /> : null}


			</>

		)
	}

}

let mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (data) => dispatch(Auth.getLoginAction(data)),
	}
}
let mapStateToProps = ({ authReducer }) => {
	let { isLoggedIn, companyInfo } = authReducer
	return {
		isLoggedIn,
		companyInfo
	}
}
export default withRouter(Root(connect(mapStateToProps, mapDispatchToProps)(BussinessProfile)));
