import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AuthService from '../services/Auth.service';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Auth from '../store/actions/Auth.action'
import Loader from '../component/loader';
import PreLogin from '../component/prelogin';
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login';
import Root from '../hoc/Root.hoc'
import facebook from "../resources/img/facebook.png";
import google from "../resources/img/google.png";

const config = require('../config/Credential.config')

class Signin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrelogin: false,
      isModalShow: false,
      marginTop: 0,
      marginTop2: 0,
      email: "",
      password: "",
      remember_me: false,
      isLoader: false,
      validator: {
        email: null,
        password: null,
      }
    }
    this.copyState = this.state
    this.copyValidator = this.state.validator
  };

  closeModal() {
    this.setState({ "isPrelogin": false })
  }


  componentDidMount() {
    let marginTop2 = (document.getElementById("mainContainer").clientHeight - document.getElementById("signinFromContent").clientHeight - 100) / 2;
    let marginTop = (document.getElementById("mainContainer").clientHeight - document.getElementById("content").clientHeight - 100) / 2;

    if (marginTop > 0) {
      this.setState({ marginTop: marginTop })
    }

    if (marginTop2 > 0) {
      this.setState({ marginTop2: marginTop2 })
    }
  }



  login = (e) => {
    e.preventDefault()
    const { email, password, remember_me } = this.state;
    if (email.trim() == "") {
      this.setState({ validator: { email: "Email is required *" } })
    } else if (!this.props.hoc.verifyEmail(email.trim())) {
      this.setState({ validator: { email: "Please enter a valid Email *" } })
    } else if (password.trim() == "") {
      this.setState({ validator: { password: "password is required *" } })
    } else {
      AuthService.login({ email, password }).then(async (res) => {
        if (res && res['success']) {
          if (res['data']['user'] && res['data']['user']['status']) {
            this.props.hoc.setLocalStorage('_token', res['data']['token'])
            if (remember_me) {
              this.props.hoc.setCookie('_rem', res['data']['token'])
            }
            this.props._login(res['data']['user'])
            await this.props.hoc.promiseAlert("Login successful", "success")
            if (!res['data']['user']['isPreDashboard']) {
              this.props.history.push(`/prelogin/${res['data']['user']['_id']}`)
            } else {
              this.props.hoc.setLocalStorage('_domain', res['data']['user']['domain'])
              this.props.history.push(`/${res['data']['user']['domain']}`)
            }
          } else {
            await this.props.hoc.promiseAlert("Login Fail", "Error");
          }


        } else {
          if (res.message && typeof res.message === 'string')
            await this.props.hoc.promiseAlert(res.message, "error")
          else
            await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
        }
      }).catch(async err => {
        this.props.hoc.cache();
        if (err.message && typeof err.message === 'string')
          await this.props.hoc.promiseAlert(err.message, "error")
        else
          await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
      })
    }
  }

  responseGoogle = (response) => {
    let formItem = {
      user_name: response.profileObj.givenName + response.profileObj.familyName,
      email: response.profileObj.email,
      access_token: response.accessToken,
      social_id: response.googleId,
      social_token_id: response.tokenId,
      loginType: "GOOGLE"
    }
    AuthService.socialLogin(formItem).then(async (res) => {
      if (res && res.success) {
        this.props._login(res['data']['user'])
        this.props.hoc.setLocalStorage('_token', res['data']['token'])
        await this.props.hoc.promiseAlert("Login successful", "success")
        if (!res['data']['user']['isPreDashboard']) {
          this.props.history.push(`/prelogin/${res['data']['user']['_id']}`)
        } else {
          this.props.hoc.setLocalStorage('_domain', res['data']['user']['domain'])
          this.props.history.push(`/${res['data']['user']['domain']}`)
        }
      }
      else {
        if (res.message && typeof res.message === 'string')
          await this.props.hoc.promiseAlert(res.message, "error")
        else
          await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
      }

    }).catch(async err => {
      if (err.message && typeof err.message === 'string')
        await this.props.hoc.promiseAlert(err.message, "error")
      else
        await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
    })
  }

  responseFacebook = (response) => {
    if (response) {
      let formItem = {
        user_name: response.name,
        email: response.email,
        access_token: response.accessToken,
        social_id: response.id,
        social_token_id: response.signedRequest,
        loginType: "FACEBOOK"
      }
      AuthService.socialLogin(formItem).then(async (res) => {
        if (res && res.success) {
          this.props._login(res['data']['user'])
          this.props.hoc.setLocalStorage('_token', res['data']['token'])
          await this.props.hoc.promiseAlert("Login successful", "success")
          if (!res['data']['user']['isPreDashboard']) {
            this.props.history.push(`/prelogin/${res['data']['user']['_id']}`)
          } else {
            this.props.hoc.setLocalStorage('_domain', res['data']['user']['domain'])
            this.props.history.push(`/${res['data']['user']['domain']}`)
          }
        }
        else {
          if (res.message && typeof res.message === 'string')
            await this.props.hoc.promiseAlert(res.message, "error")
          else
            await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
        }
      }).catch(async err => {
        if (err.message && typeof err.message === 'string')
          await this.props.hoc.promiseAlert(err.message, "error")
        else
          await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
      })
    }
  }

  responseGoogleErr = async (e, err) => {
    // await this.props.hoc.promiseAlert("Somthing went wrong, please try again later", "error")
  }
  handleText = (text, name) => {
    this.setState({ [name]: text, validator: this.copyValidator })

  }
  enterPressed = (event) => {
    var code = event.keyCode;
    if (code === 13) {
      event.preventDefault()//13 is the enter keycode
      this.login(event)
    }
    else {
      return true
    }
  }

  render() {

    //let marginTop = document.getElementById("signinFrom").clientHeight

    return (

      <>

        <Container fluid className="pt-4 pb-4 signin theme-yellow" style={{ minHeight: window.innerHeight - 80 + 'px' }}>
          <Container id="mainContainer">
            <Row>
              <Col xs={12} md={7} className="p-0" style={{ marginTop: this.state.marginTop }}>
                <Card style={{ width: '100%' }} className="theme-yellow custom-card" >
                  <Card.Body className="pl-0" id="content">
                    <Card.Title className="header pb-2 theme-font" style={{ fontSize: 44, fontWeight: 900 }}> Disrupt Your Purchasing Process & Streamline Recurring Purchases for Free!</Card.Title>

                    <Card.Text className="pb-0 theme-font basic-text" style={{ fontSize: 20, lineHeight: "28px" }} >Cut wasted time to restock everyday business supplies. Axenomic remembers your supply needs, enabling you to focus on running our business!</Card.Text>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={5} className="p-0">
                {/*<div className="form-center" style={{ marginTop: this.state.marginTop + 'px' }} id="signinFromContent" >*/}
                <div className="form-center mt-4" id="signinFromContent" style={{ marginTop: this.state.marginTop2 + 'px' }}>
                  <h3 className="header2 pb-4 pt-0 mb-2 theme-font text-center"><span className="span-bold">Log In</span></h3>
                  <Form >
                    <Form.Group controlId="formBasicEmail">

                      <Form.Control onKeyDown={this.enterPressed} type="email" placeholder="Enter email" onChange={(ref) => this.handleText(ref.target.value, "email")} value={this.state.email} />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                      {this.state.validator.email && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.email}</span>}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">

                      <Form.Control onKeyDown={this.enterPressed} type="password" placeholder="Password" onChange={(ref) => this.handleText(ref.target.value, "password")} value={this.state.password} />
                      {this.state.validator.password && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.password}</span>}
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Remember me" className="col-sm-5 float-left" />
                      <Link to="/forgot-password" className="mb-3 forgot-pass float-right">Forgot Password?</Link>
                    </Form.Group>



                    <Button onClick={this.login} variant="primary" className="theme-button width100 mb-2">
                      Sign In
                    </Button>

                    <Link to="/signup" className=" btn btn-primary theme-button theme-button2 width100 mb-2">Sign Up</Link>

                    {/* <div className="seperator"><span className="border-long left-border-long"></span>or<span className="border-long right-border-long"></span></div> */}
                    {/* <FacebookLogin
                      appId="778406716059691"
                      autoLoad={false}
                      redirectUri={config.redirectUrl}
                      fields="name,email,picture"
                      cssClass="width100 mb-2 facebok pos-relative"
                      callback={this.responseFacebook}
                      textButton="Facebook"
                      style={{
                        paddingVertical: 30,

                      }}
                      icon={
                        <img
                          src={facebook}
                          width="20"
                          height="20"
                          className="d-inline-block align-top mr-2 social-img"
                          alt="Axenomic logo"
                        />
                      }
                    /> */}

                    {/* <GoogleLogin
                      clientId="1040647994574-fuecjoplmpc7bvj0kqjgvbh6i47g3ol4.apps.googleusercontent.com"
                      buttonText="Google"
                      redirectUri={config.redirectUrl}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogleErr}
                      cookiePolicy={'single_host_origin'}
                      render={renderProps => (
                        <button variant="primary" className="width100 mb-2 google pos-relative" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                          <img
                            src={google}
                            width="20"
                            height="20"
                            className="d-inline-block align-top mr-2 social-img"
                            alt="Axenomic logo"
                          />
                          Google
                        </button>
                      )}
                    /> */}

                  </Form>
                </div>
              </Col>
            </Row>

          </Container>
        </Container>
        <PreLogin isModalShow={this.state.isPrelogin} closeModal={() => { this.closeModal() }} />
        {this.state.isLoader ? <Loader /> : null}

      </>

    )
  }

}

let mapDispatchToProps = (dispatch) => {
  return {
    _login: (data) => dispatch(Auth.getLoginAction(data)),
  }
}
let mapStateToProps = ({ authReducer }) => {
  let { isLoggedIn, test } = authReducer
  return {
    isLoggedIn: isLoggedIn
  }
}

export default withRouter(Root(connect(mapStateToProps, mapDispatchToProps)(Signin)))
