import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
const CryptoJS = require("crypto-js");

class Base {
    constructor() {
        this.MySwal = withReactContent(Swal)
    }
    verifyEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true
        }
        else {
            return false
        }
    }

    alertMessage = (title = "Error!", message = "Field is required.", purpuse = 'destructive') => {
        return this.MySwal.fire({
            title: <p>{message}</p>,
            onOpen: () => {
                this.MySwal.clickConfirm()
            }
        }).then(() => {
            return this.MySwal.fire(<p>{message}</p>)
        })
    }

    toTitleCase = (str) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    dateFormat = (date) => {
        let dayPart = this.ordinal_suffix_of(date.getDate())
        let monthPart = date.toLocaleString('default', { month: 'long' })
        let yearPart = date.getFullYear()
        return dayPart + ' ' + monthPart + ' ' + yearPart
    }

    ordinal_suffix_of = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    getToken() {
        return localStorage.getItem("_token");
    }

    saveToken(token) {
        localStorage.setItem("_token", token);
    }

    decodeToken(token) {
        const decodeToken = JSON.parse(atob(this.payload(token)));
        if (decodeToken['user']) {
            const bytes = CryptoJS.AES.decrypt(decodeToken.user, 'secret key 123');
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            if (originalText) {
                return this.userDecode(originalText);
            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    payload(token) {
        return token.split('.')[1];
    }

    userDecode(userString) {
        const data = JSON.parse(userString);
        delete data.password;
        delete data.domain;
        return data;
    }

    checkAuthenticateUser() {
        const __token = this.getToken();
        if (__token) {
            const data = this.decodeToken(__token);
            if (data) {
                //
            }
        }

    }
}

export default new Base()