import React, { Component } from 'react'
import { connect } from 'react-redux'
import Root from '../hoc/Root.hoc'
import { withRouter } from "react-router";
import CartAction from '../store/actions/Cart.action'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import config from '../config/Credential.config';
import BussinesKey from '../component/bussinesskey';
import Auth from '../store/actions/Auth.action'
import EmployeeService from "../services/employeecart.service";
const jwt = require('jsonwebtoken');
const hash = require("password-hash");


class Employeecart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            temp_cart: [],
            mrTop: 0,
            modalShow: false,
            _domain: props.hoc.getLocalStorage("_domain") ? props.hoc.getLocalStorage("_domain") : null,
            TotalAmount: 0,
            totalItems: this.props.hoc.getLocalStorage("cartCount") ? this.props.hoc.getLocalStorage("cartCount") : 0
        }
        this.copyState = this.state
    }

    static getDerivedStateFromProps(props, state) {
        return {
            mrTop: state.mrTop,
            temp_cart: props.temp_cart
        }
    }

    componentDidMount = () => {
        this.setData()
        // let products = [
        //     {ASIN: "B07K7VMBFT", quantity: 1},
        //     {ASIN: "B06Y22V315", quantity: 2},
        //     {ASIN: "B07NZX7ZFB", quantity: 3},
        // ]
    }


    redirectToAmazon = (products) => {
        let url = `https://www.amazon.com/gp/aws/cart/add.html?AWSAccessKeyId=${config.paapiCred.AccessKey}&AssociateTag=${config.paapiCred.PartnerTag}`
        for (let i = 0; i < products.length; i++) {
            url += `&ASIN.${i + 1}=${products[i]['ASIN']}&Quantity.${i + 1}=${products[i]['quantity'] ? products[i]['quantity'] : '1'}`
            if (i == products.length - 1) {
                return url
            }
        }
    }

    setData = () => {
        if (this.props.temp_cart && this.props.temp_cart.length) {
            this.setState({ temp_cart: this.props.temp_cart });
        } else {
            let local_cart = JSON.parse(this.props.hoc.getLocalStorage("temp_cart"))
            if (local_cart) {
                this.props.cart_action(local_cart, "ADD")
                this.setState({ temp_cart: local_cart });
            }
        }
        this.totalAmount();
        this.totalItems();
        this.props.save_cart_in_onload();
    }

    totalAmount = () => {
        let data = this.props.hoc.getLocalStorage("temp_cart") ? JSON.parse(this.props.hoc.getLocalStorage("temp_cart")) : [];
        if (data.length) {
            let amount = 0;
            for (let i = 0; i < data.length; i++) {
                amount = amount + +(data[i].Amount * +(data[i].quantity));

                if (i == (data.length -1)) {
                    this.setState({TotalAmount: amount.toFixed(2)})
                }
            }
        }
    }

    totalItems = () => {
        let data = this.props.hoc.getLocalStorage("temp_cart") ? JSON.parse(this.props.hoc.getLocalStorage("temp_cart")) : [];
        if (data) {
            let quantity = 0;
            for (let i = 0; i < data.length; i++) {
                quantity = quantity + +(data[i].quantity);

                if (i == (data.length -1)) {
                    this.setState({totalItems: quantity})
                }
            }
        }
    }

    removeItems = async (index, item) => {
        await this.props.hoc.promiseAlert(`${item.DisplayValue} remove to cart successfully!`, "success");
        await this.props.remove_cart(index)
        await this.props.sub_count_cart(item['quantity'])
        this.setData()
    }

    setMargin(id) {
        var elmnt = document.getElementById(id);
        var elmnt2 = document.getElementById("parentHeight");

        var mrTop = (parseFloat(elmnt2.offsetHeight) - parseFloat(elmnt.offsetHeight)) / 2;

        if (mrTop > 0) {
            this.setState({ mrTop: mrTop })
        }

    }

    closeModal = async () => {
        await this.setState({modalShow: false});
    }

    submitPrivetKey = async () => {
        await this.setState({modalShow: false});
        this.processToChecout();
    }

    abcd = () => {
        //
    }

    processToChecout = () => {
        /**-----------------Old check out process ----------------------------------------
         * const orderData = this.processOderData();
        let _token = this.props.hoc.getLocalStorage("_employeeToken");
        let _employeeKey = this.props.hoc.getLocalStorage("_employeeKey");
        if (_token && _employeeKey) {
            const decoded = jwt.verify(_token, config.jwtSecret);
            if (decoded && decoded.data &&  hash.verify(decoded.data, _employeeKey)) {
                let getAmazonCartUrl = this.redirectToAmazon(this.state.temp_cart);
                EmployeeService.lastOrderDate(orderData).then(res => {
                    if (res && res.success) {
                        this.props.remove_all();
                        window.open(getAmazonCartUrl, '_blank');
                    } else {
                        this.props.hoc.promiseAlert(`Somthing went to wrong`, "error"); 
                    }
                }).catch(err => {
                    this.props.hoc.promiseAlert(`Internal server error!`, "error");
                })
                
            } else {
                this.props.hoc.promiseAlert(`Your not authoraize!`, "error");
            }
        } else {
            this.setState({modalShow: true});
            // this.props.hoc.promiseAlert(`Your not authoraize!`, "error");
        }----------------------------------------------------------------------------------**/

        //===============> New checkout process <======================//
        const orderData = this.processOderData();
        let getAmazonCartUrl = this.redirectToAmazon(this.state.temp_cart);
        EmployeeService.lastOrderDate(orderData).then(res => {
            if (res && res.success) {
                this.props.remove_all();
                window.open(getAmazonCartUrl, '_blank');
            } else {
                this.props.hoc.promiseAlert(`Somthing went to wrong`, "error"); 
            }
        }).catch(err => {
            this.props.hoc.promiseAlert(`Internal server error!`, "error");
        });
        
    }

    processOderData = () => {
        const dataSet = JSON.parse(this.props.hoc.getLocalStorage("temp_cart"));
        let data = [];
        if (dataSet.length) {
            for (let i = 0; i < dataSet.length; i++) {
                let object = {
                    company_domain: this.props.hoc.getLocalStorage("_domain"),
                    product_id: dataSet[i]['_id'],
                    order_date: new Date().getTime()
                };
                data.push(object);

                if (i == (dataSet.length - 1)) {
                    return data;
                }
            }
        } else {
            return data;
        }
        
    }

    manageQuantityManualy = async (e, index) => {
        // let getCartData = await this.props.hoc.getLocalStorage("temp_cart") ? JSON.parse(this.props.hoc.getLocalStorage("temp_cart")) : [];
        // if (getCartData.length && e) {
        //     getCartData[index]['quantity'] = await e;
        //     await localStorage.removeItem("temp_cart")
        //     await localStorage.setItem("temp_cart", JSON.stringify(getCartData))
        //     this.props.cart_action(getCartData)
        //     this.props.add_cart_count(getCartData[index]['quantity'])
        //     this.totalItems();
        //     this.totalAmount();
        // }
    }

    manageQuantity = async (e, item, cond = null) => {
        e.preventDefault()
        if (item && item.quantity && cond) {
            this.manageCart(item, cond).then(async data => {
                if (data) {
                    await localStorage.removeItem("temp_cart")
                    await localStorage.setItem("temp_cart", JSON.stringify(data))
                    this.props.cart_action(data)
                    this.totalItems();
                    this.totalAmount();
                }
                else {
                    this.props.hoc.promiseAlert("Something went wrong", "error")
                }
            })
        }
    }

    manageCart = (item, cond) => {
        return new Promise(async (resolve, reject) => {
            let temp_cart = await localStorage.getItem("temp_cart") ? JSON.parse(localStorage.getItem("temp_cart")) : []
            await temp_cart.map(p => {
                if (p._id == item._id) {
                    if (cond == "ADD") {
                        this.props.add_cart_count()
                    } else if (p['quantity'] > 1) {
                        this.props.sub_count_cart()
                    }
                    p['quantity'] = cond == "ADD" ? +p['quantity'] + 1 : (p['quantity'] > 1 ? +p['quantity'] - 1 : p['quantity'])
                }
            })
            resolve(temp_cart)
        })
    }

    render() {
        return (
            
            <Container fluid className="pt-4 pb-4 signin" style={{ minHeight: window.innerHeight - 80 + 'px' }}>
                <BussinesKey isModalShow={this.state.modalShow} _domain={this.state._domain} closeModal={() => { this.closeModal() }} _login={(token) => { this.props._login(token) }} submitPrivetKey={ () => {this.submitPrivetKey()} } loginMessage={"Access Granted"}/>
                <Container id="mainContainer">
                    <Row>
                    <h3 className="header2 pb-3 pt-2 mb-4 mt-3 theme-font border-bottom"> Shoping cart </h3>

                        {
                            this.state.temp_cart.length ?
                                this.state.temp_cart.map((item, i) => {
                                    return (
                                        <div className="col-sm-12 p-0 mb-2 border-bottom" key={item._id}>

                                            <Col xs={12} md={3} className="float-left" id="parentHeight">

                                                <Figure style={{ marginTop: this.state.mrTop, width: "100%" }}>
                                                    {
                                                        item.ImageURL ? <Figure.Image
                                                            width="90%"
                                                            height={180}
                                                            alt="171x180"
                                                            id={"img" + item.ImageURL}

                                                            onLoad={() => { this.setMargin("img" + item.ImageURL) }}
                                                            src={item.ImageURL}
                                                        /> : <Figure.Image
                                                                width="90%"
                                                                height={180}
                                                                alt="171x180"
                                                                id={"img" + require("../resources/img/images.png")}

                                                                onLoad={() => { this.setMargin("img" + require("../resources/img/images.png")) }}
                                                                src={require("../resources/img/images.png")}
                                                            />
                                                    }

                                                </Figure>


                                            </Col>

                                            <Col xs={12} md={8} className="float-left">

                                                <Card style={{ width: '100%' }} className="theme-yellow custom-card">
                                                    <Card.Body>
                                                        <Card.Title className="header pb-2 theme-font small-font">
                                                            <span className="span-bold">{item.DisplayValue ? item.DisplayValue : ""}</span>
                                                        </Card.Title>
                                                        <Card.Text className="pb-0 theme-font basic-text">
                                                            {item.DisplayValue ? item.DisplayValue : ""}
                                                        </Card.Text>

                                                        <div className="col-sm-8 p-0">
                                                            <Form.Control
                                                                type="text"
                                                                className="col-sm-2 mr-2 float-left"
                                                                id="inlineFormInputName2"
                                                                placeholder="Quantity"
                                                                readOnly={true}
                                                                value={item.quantity}
                                                                onChange={(e) => {this.manageQuantityManualy(e.target.value, i)}}
                                                            />
                                                            <button className="btn theme-button2 mr-2 col-sm-1 p-2"  onClick={(e) => this.manageQuantity(e, item, "ADD")}>+</button>
                                                            <button className="btn theme-button2 mr-2 col-sm-1 p-2"  onClick={(e) => this.manageQuantity(e, item, "SUB")}>-</button>

                                                            <button className="btn theme-button col-sm-4 p-2" onClick={() => {this.removeItems(i, item)}}>Remove from cart</button>
                                                       </div>
                                                    </Card.Body>
                                                </Card>

                                            </Col>
                                                <p className="price-list">{(item.Currency == "USD") ? "$" : ""}{item.Amount ? item.Amount: 0}</p>
                                        </div>


                                    )
                                })

                                : <p className="noitem">No Item to Display</p>}
                        {
                            this.state.temp_cart.length ?
                            <div className="col-sm-12 p-0 mb-2 d-flex justify-content-between">
                                <p className="cart-text ">The price and availability of items at Amazon.com are subject to change. The Cart is a temporary place to store a list of your items and reflects each item's most recent price. </p>
                                <p className="subtotal">Subtotal : <span className="item">({this.state.totalItems} Items)</span> - <span className="total">${this.state.TotalAmount}</span></p>
                            </div> : null
                        }
                            
                        {
                            this.state.temp_cart.length ? <Button variant="primary" className="theme-button2 pl-4 pr-4 mb-2 p-2" type="submit" onClick={() => { this.processToChecout() }} >
                                Checkout to amazon.com
                            </Button> : null
                        }

                    </Row>
                </Container>
            </Container>
        )
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        cart_action: (data, action) => dispatch(CartAction.getTemporaryCartAction(data, action)),
        remove_cart: (data) => dispatch(CartAction.removeCrat(data)),
        sub_count_cart: (quantity) => dispatch(CartAction.cartRemoveCount(quantity)),
        save_cart_in_onload: () => dispatch(CartAction.onLoadCartSave()),
        remove_all: () => dispatch(CartAction.removeAllCart()),
        _login: (data) => dispatch(Auth.getEmployeeLogin(data)),
        add_cart_count: (data) => dispatch(CartAction.cartSaveCount(data))
    }
}
let mapStateToProps = ({ cartReducer }) => {
    let { temp_cart } = cartReducer
    return {
        temp_cart
        // isLoggedIn: isLoggedIn
    }
}
export default withRouter(Root(connect(mapStateToProps, mapDispatchToProps)(Employeecart)));
