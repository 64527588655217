import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../theme/custom.css';

import Topnav from './topnavigation'

import Home from '../pages/home'
import Signin from '../pages/signin'
import Signup from '../pages/signup'
import Store from '../pages/stores'
import Faq from '../pages/faq'
import NewRequset from '../pages/newrequest'
import VerifyEmail from '../pages/VerifyEmail.page'
import ErrorPage from "../pages/404";
import Employeecart from "../pages/employeecart"
import ForgetPassword from "../pages/forgetPassword";

//import VerifyEmailTemplate from '../pages/VerifyEmialTemplate'

import BussinessInfo from '../pages/bussinessinfo'
import BussinessProfile from '../pages/bussinessprofile'
import config from "../config/Credential.config";

import PreLoginPage from "../pages/prelogin";
import TermsOfServices from "../pages/termsOfServices";
import privacyPolicy from "../pages/privacyPolicy";

export default function RouterComponent() {
    let checkAdmin = () => {
        let getToken = localStorage.getItem("_token");
        if (getToken) {
            return <BussinessProfile />
        } else {
            return <BussinessInfo />
        }
    }
    return (
        <Router>
            < Topnav />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/newrequest" component={NewRequset} />
                <Route exact path="/signin" component={Signin} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/stores" component={Store} />
                <Route exact path="/prelogin/:_id" component={PreLoginPage} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/verify-email/:email" component={VerifyEmail} />
                <Route exact path="/forgot-password" component={ForgetPassword} />
                <Route exact path="/404" component={ErrorPage} />
                <Route exact path="/employeecart" component={Employeecart} />
                <Route exact path="/terms-of-services" component={TermsOfServices} />
                <Route exact path="/privacy-policy" component={privacyPolicy} />
                <Route exact path="/:domain" component={checkAdmin} />
                <Route path="**" component={ErrorPage} />
            </Switch>
        </Router>
    )
}
