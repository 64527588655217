import BaseService from './Base.service.js'
import { serviceUrl, productSlug } from '../config/Credential.config'

class productService extends BaseService {
    constructor(props) {
        super(props)
    }
    searchFromAmazon = (searchKey, page = 1) => {
        this.serviceUrl = serviceUrl+productSlug+`search-items/${searchKey}/${page}`
        return this.post({}).then((response) => response.json())
    }
    getAmazonProductFromUrl = (url)=> {
        this.serviceUrl = serviceUrl+productSlug+`get-item-from-url`
        return this.post({productUrl: url}).then((response) => response.json())
    }
    saveAmazonProducts = (data, token) => {
        this.serviceUrl = serviceUrl+productSlug+`save-amazon-products`
        return this.post(data, token).then((response) => response.json())
    }
    getProducts = (domain) => {
        this.serviceUrl = serviceUrl+productSlug+`get-products/${domain}`
        return this.get().then((response) => response.json())
    }
    removeProducts = (data, token) => {
        this.serviceUrl = serviceUrl+productSlug+`remove-products`
        return this.post(data, token).then((response) => response.json())
    }
    saveNewReq = (data) => {
        this.serviceUrl = serviceUrl+productSlug+`req-new-product`
        return this.post(data).then((response) => response.json())
    }
    getAllRequests = (data, token) => {
        this.serviceUrl = serviceUrl+productSlug+`get-all-requests`
        return this.get(data, token).then((response) => response.json())
    }
    saveProdFromReq = (data, token) => {
        this.serviceUrl = serviceUrl+productSlug+`save-requested-products`
        return this.post(data, token).then((response) => response.json())
    }
    checkAvaility = (data) => {
        this.serviceUrl = serviceUrl+productSlug+`check-products-availability/${data}`
        return this.get(data).then((response) => response.json())
    }
}
let ProductService = new productService()

export default ProductService