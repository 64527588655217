import BaseService from './Base.service.js'
import { serviceUrl, employeSlug } from '../config/Credential.config'

class employeecart extends BaseService {
    constructor(props) {
        super(props)
    }

    lastOrderDate = (data) => {
        this.serviceUrl = serviceUrl+employeSlug+'last-order-date';
        return this.post(data, null).then((response) => response.json())
    }
}

export default new employeecart();