import React from 'react';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link
} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { withRouter } from "react-router";
import axenomic from "../resources/img/axenomic.png";
import axenomic2 from "../resources/img/axenomic2.png";
import axenomic3 from "../resources/img/axenomic3.png";

class Banner extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         data: 0
      }
   };
   componentDidMount() {
      //
   }
   shouldComponentUpdate(newProps, newState) {
      return true;
   }
   componentDidUpdate(prevProps, prevState) {
      //
   }
   componentWillUnmount() {
      //
   }

   render() {
      return (
         <Carousel controls={false} indicators={true} className="custom-carousal mt-5">
            <Carousel.Item className="custom-carousal-item">

               <Col xs={12} md={12} className="float-left">

                  <Figure>
                     <Figure.Image
                        width="90%"
                        height={180}
                        alt="171x180"
                        src={axenomic}
                     />

                  </Figure>

               </Col>

            </Carousel.Item>
            <Carousel.Item className="custom-carousal-item">

               <Col xs={12} md={12} className="float-left">

                  <Figure>
                     <Figure.Image
                        width="90%"
                        height={180}
                        alt="171x180"
                        src={axenomic2}
                     />

                  </Figure>

               </Col>

            </Carousel.Item>
            <Carousel.Item className="custom-carousal-item">

               <Col xs={12} md={12} className="float-left">

                  <Figure>
                     <Figure.Image
                        width="90%"
                        height={180}
                        alt="171x180"
                        src={axenomic3}
                     />

                  </Figure>

               </Col>

            </Carousel.Item>
            <Carousel.Item className="custom-carousal-item">

               <Col xs={12} md={12} className="float-left">

                  <Figure>
                     <Figure.Image
                        width="90%"
                        height={180}
                        alt="171x180"
                        src={axenomic}
                     />

                  </Figure>

               </Col>

            </Carousel.Item>

         </Carousel>
      )
   }

}
export default withRouter(Banner);
