import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

class Loader extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         data: 0
      }
   };


   render() {

      return (

         <>
            <div className="loader">
               <Spinner className="spinner" variant="danger" animation="border" />
            </div>
         </>

      )
   }

}
export default Loader;