let initState = {
    token: "",
    isLoggedIn: false,
}
let EmployeeAuthReducer = (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'EMPLOYEE':
            return Object.assign({}, state, { token: payload, isLoggedIn: true })
        default:
            return state
    }
}

export default EmployeeAuthReducer