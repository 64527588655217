import React, { Component } from 'react'
import {
    Link
} from "react-router-dom";
import AuthService from '../services/Auth.service'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { parse } from 'query-string';
import Root from '../hoc/Root.hoc'
import { withRouter } from "react-router";
import config from "../config/Credential.config";
const jwt = require('jsonwebtoken');

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: "",
            message: "",
            emailInput: true,
            passwordInput: false,
            email: "",
            password: "",
            confirmPassword: "",
            _id: "",
            timeOutUrl: false, 
            validator: {
                email: null,
                password: null,
                confirmPassword: null
            }
        }
        this.copyValidator = this.state.validator
    }
    static getDerivedStateFromProps(props, state) {
        if (!props.location.search)
            return {
                emailInput: true,
                passwordInput: false 
            }
        else {
            return { token: parse(props.location.search), 
                emailInput: false,
                passwordInput: true  }
        }
    }
    componentDidMount = () => {
        if (!this.state.emailInput && this.state.passwordInput) {
            this.decodeToken();
        }
    }

    decodeToken = async () => {
        try {
            let decoded = jwt.verify(this.state.token['token'], config.jwtSecret);
            let endTime = decoded.data.expr;
            let currentTime = new Date().getTime();
            let timeOut = currentTime - endTime;
            if (timeOut <= 0) {
                this.setState({timeOutUrl: true});
                await this.props.hoc.promiseAlert("This url is expiered", "error");
            } else {
                this.setState({
                    email: decoded.data.email,
                    _id: decoded.data._id
                });
            }
        } catch (err) {
            await this.props.hoc.promiseAlert("Something went to wrong!", "error");
        } 
    }
    
    verifyEmail = (e) => {
        e.preventDefault();
        if (this.state.emailInput && !this.state.passwordInput) {
            if (this.state.email.trim() == "") {
                this.setState({ validator: { email: "Email is required *" } })
            } else if (!this.props.hoc.verifyEmail(this.state.email.trim())) {
                this.setState({ validator: { email: "Please enter a valid Email *" } })
            } else {
                AuthService.forgotPassword({email: this.state.email}).then(async (data) => {
                    if (data && data.success) {
                        await this.props.hoc.promiseAlert(data.message, "success")
                    } else {   
                        await this.props.hoc.promiseAlert("User not found", "error");
                    }
                }).catch(async err => {
                    await this.props.hoc.promiseAlert("Server problem. please click the email link again.", "error");
                })
            }
        } else if (!this.state.emailInput && this.state.passwordInput) {
            if (this.state.password.trim() == "") {
                this.setState({ validator: { password: "Password is required *" } })
            } else if (!new RegExp("(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}").test(this.state.password.trim())) {
                this.setState({ validator: { password: "Password must contain 8 characters, minimum one Upper case and Lower case and one special character *" } })
            } else if (this.state.confirmPassword.trim() == "") {
                this.setState({ validator: { confirmPassword: "Confirm password is required *" } })
            } else if (this.state.confirmPassword !== this.state.password) {
                this.setState({ validator: { confirmPassword: "Confirm password is not match *" } })
            } else {
                AuthService.forgotPassword({email: this.state.email, password: this.state.password, _id: this.state._id}).then(async (data) => {
                    if (data && data.success) {
                        await this.props.hoc.promiseAlert(data.message, "success")
                        this.props.history.push(`/signin`);
                    } else {   
                        await this.props.hoc.promiseAlert("User not found", "error");
                    }
                }).catch(async err => {
                    await this.props.hoc.promiseAlert("Server problem. please click the email link again.", "error");
                })
            }
        }
        
    }

    handleText = (text, name) => {
        this.setState({ [name]: text, validator: this.copyValidator }) 
    }

    enterPressed = (event) => {
        var code = event.keyCode;
        if (code === 13) {
          event.preventDefault()//13 is the enter keycode
          this.verifyEmail(event)
        }
        else {
          return true
        }
      }

    render() {
        return (
            <Container fluid className="pt-4 pb-4 signin theme-yellow" style={{ minHeight: window.innerHeight - 80 + 'px'}}>
                {
                    !this.state.timeOutUrl ? 
                    <Container id="mainContainer text-center">
                        <Row>
                            <Col style={{ papping: 20, justifyContent: 'center', alignItems: 'center' }} className="pt-5 mt-5 form-center2">
                                <h3 className="theme-header2">
                                {(this.state.emailInput && !this.state.passwordInput) ? "Enter Your Email-ID" : (!this.state.emailInput && this.state.passwordInput) ? "Set Your Password" : ""}
                                </h3>
                                <Form >
                                    
                                    {
                                        ((this.state.emailInput && !this.state.passwordInput) ? <Form.Group controlId="formBasicEmail">

                                            <Form.Control onKeyDown={this.enterPressed}  type="email" placeholder="Enter email" onChange={(ref) => this.handleText(ref.target.value, "email")} value={this.state.email} />
                                            <Form.Text className="text-muted">
                                            Please enter your registered email address.
                                            </Form.Text>
                                            {this.state.validator.email && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.email}</span>}
                                            
                                        </Form.Group> 
                                        : (!this.state.emailInput && this.state.passwordInput) ? <Form.Group controlId="formBasicPassword">

                                            <Form.Control onKeyDown={this.enterPressed}  type="password" placeholder="Enter New Password" onChange={(ref) => this.handleText(ref.target.value, "password")} value={this.state.password}/>
                                            <Form.Text className="text-muted">
                                            Must have 8 characters, upper case, lower case and special character.
                                            </Form.Text>
                                            {this.state.validator.password && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.password}</span>}

                                            <Form.Control onKeyDown={this.enterPressed}  type="password" placeholder="Re-enter to Confirm Password" onChange={(ref) => this.handleText(ref.target.value, "confirmPassword")} value={this.state.confirmPassword} />
                                            <Form.Text className="text-muted">Please re-enter the password to confirm.
                                            </Form.Text>
                                            {this.state.validator.confirmPassword && <span style={{ color: 'red', fontSize: 10 }}>{this.state.validator.confirmPassword}</span>}
                                        </Form.Group> 
                                        : null)
                                    }
                                    <Button onClick={this.verifyEmail} variant="primary" className="theme-button width100 mb-2">
                                    Submit
                                    </Button>
                                </Form>
                                {/* <Link to="/signin" className=" btn btn-primary theme-button3 p-2 pl-3 pr-3 mt-4" >Go To Login</Link> */}
                            </Col>
                        </Row>

                    </Container>
                    : null
                }
                
            </Container>
        )
    }
}

export default withRouter(Root(ForgotPassword));
