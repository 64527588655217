import React from 'react';
import {
    Link
} from "react-router-dom";
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import 'react-rater/lib/react-rater.css'
import Common from '../hoc/Common.hoc';
import default_images from "../resources/img/images.png";

import { serviceUrl } from "../config/Credential.config"

class CompanyList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: 0,
            company: {
                business_logo: "",
                domain: "",
                business_name: ""
            },
            key: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            company: props.company ? props.company : { business_logo: "", domain: "", business_name: "" },
            key: props.key ? props.key : ""
        }

    }




    render() {


        return (
            <>
                <Card as={Link} to={`/${this.state.company.domain}`} className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 200, overflow: "hidden" }} >
                    <Figure className="link-image">
                        <Figure.Image
                            // width="180px"
                            // height="100px"
                            alt="171x180"
                            src={this.state.company.business_logo ? serviceUrl + "company_logo/" + this.state.company.business_logo : default_images }
                            style={{
                                // width: "100%",
                                height: 120
                            }}
                        />
                        <Figure.Caption className="company-name">{this.state.company.business_name}</Figure.Caption>
                    </Figure>
                </Card>

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="180px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/tcs.png")}
                                    />

                              </Figure>

                        </Card> */}

                {/* <Card className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>
                              <a href="#" className="link-text pb-2">
                                    <Figure className="link-image">
                                          <Figure.Image
                                                width="180px"
                                                //height={180}
                                                alt="171x180"
                                                src={require("../resources/img/wipro.png")}
                                          />

                                    </Figure>
                              </a>
                        </Card> */}

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="170px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/hcl.png")}
                                    />

                              </Figure>

                        </Card> */}

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="180px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/bicon.png")}
                                    /> */}
                {/*<Figure.Caption>T</Figure.Caption>*/}
                {/* </Figure>

                        </Card> */}

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="180px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/wipro.png")}
                                    />

                              </Figure>

                        </Card> */}

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="180px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/google-logo.png")}
                                    />

                              </Figure>

                        </Card> */}

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="180px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/bicon.png")}
                                    />

                              </Figure>

                        </Card> */}

                {/* <Card as={Link} to="/bussiness" className="col-md-3 custom-card col-xs-12 mb-4 text-center" style={{ height: 150, overflow: "hidden" }} key={this.props.hoc.makeId(8)}>

                              <Figure className="link-image">
                                    <Figure.Image
                                          width="170px"
                                          //height={180}
                                          alt="171x180"
                                          src={require("../resources/img/hcl.png")}
                                    />

                              </Figure>

                        </Card> */}
            </>

        )
    }

}
export default Common(CompanyList);