import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import QuickLink from '../component/quicklinks';
import CompanyList from '../component/companyList';
import Footer from '../component/footer2';
import Root from '../hoc/Root.hoc';
import { withRouter } from "react-router";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import CompanyService from "../services/Company.service";
import Loader from '../component/loader';

class Store extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companies: [],
			isLoader: false,
			itemsToSearch: [],
			searchKey: "",
			quicklinksData: [{
				image: require("../resources/img/product6.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 5,
				price: 20,
				isNew: true
			},
			{
				image: require("../resources/img/product4.png"),
				title: "Chair",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 2,
				price: 180,
				isNew: false
			},
			{
				image: require("../resources/img/product2.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 3,
				price: 120,
				isNew: false
			},
			{
				image: require("../resources/img/product4.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 4,
				price: 70,
				isNew: false
			},
			{
				image: require("../resources/img/product3.png"),
				title: "Apple",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 1,
				price: 50,
				isNew: true
			},
			{
				image: require("../resources/img/product5.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 3,
				price: 100,
				isNew: false
			}],
			items: [
				{
					id: 0,
					name: "TATA",
				},
				{
					id: 1,
					name: "DIAMOND"
				},
				{
					id: 2,
					name: "HCL"
				},
				{
					id: 3,
					name: "BIOCON"
				},
				{
					id: 4,
					name: "AXENOMIC"
				},
			]
		}
		this.copyCompanies = []
	};

	goToSignup = () => {
		//
	}

	searchCompany = async () => {
		if(this.state.searchKey != "" && this.copyCompanies.length) {
			let companies = await this.copyCompanies.filter(p => p.business_name.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) != -1)
			this.setState({companies: companies})
		}
		else {
			this.setState({companies: this.copyCompanies})
		}
	}

	handleOnSearch = async(string, cached) => {
		await this.setState({searchKey: string})
		this.searchCompany()
		// onSearch returns the string searched and if
		// the values are cached. If the values are cached
		// "cached" contains the cached values, if not, returns false
	}

	handleOnSelect = item => {
		// the item selected
		this.searchCompany()
	}

	handleOnFocus = () => {
		//
	}

	componentDidMount() {
		this.getCompanies()
	}
	shouldComponentUpdate(newProps, newState) {
		return true;
	}
	componentDidUpdate(prevProps, prevState) {
		//
	}
	componentWillUnmount() {
		//
	}

	getCompanies = () => {
		this.setState({isLoader:true});
		CompanyService.getCompanies().then(async data => {
			this.setState({isLoader:false});
			if (data && data.success) {
				let names = []
				await data.data.map(item => {
					names.push({
						id: item._id,
						name: item.business_name
					})
				})
				this.setState({ companies: data['data'], itemsToSearch: names})
				this.copyCompanies = data.data
			}
		}).catch(err => {
			this.setState({isLoader:false});
		})
	}

	render() {

		let links = [];

		this.state.quicklinksData.map((link, ind) => (
			links.push(<QuickLink
				image={link.image}
				title={link.title}
				desc={link.desc}
				rating={link.rating}
				key={ind}
				price={link.price}
				isNew={link.isNew}
				gridWidth="col-md-2"
			/>)
		))

		return (
			<>
				<Container fluid className="pt-2 pb-4">
					<Container>
						<Row>
							<Col xs={12} md={6}>
								<h3 className="header2 pb-2 pt-2 mb-4 mt-3 theme-font"><span className="span-bold">Select</span> your organization</h3>
							</Col>
						</Row>
						<Row >
							<Col xs={12} md={4} className="pt-0 mt-0 mb-4">
								<Form className="inline-search">
									<Form.Group controlId="formBasicText">

										{/* <Form.Control type="text" placeholder="Search" /> */}
										<ReactSearchAutocomplete
											items={this.state.itemsToSearch}
											onSearch={this.handleOnSearch}
											onSelect={this.handleOnSelect}
											onFocus={this.handleOnFocus}
											className="jjj"
											autoFocus
										/>

									</Form.Group>
									{/* <Button variant="primary" type="submit">

									</Button> */}
								</Form>
							</Col>
							{/* <Col xs={12} md={8} className="pt-0 mt-0 mb-4 text-right">
								<Button variant="primary" className=" theme-button2 pl-4 pr-4 mb-2 p-2" type="submit">
									Add Your Team
							  </Button>
							</Col> */}
						</Row>
						<Row style={{ minHeight: window.innerHeight - 300 + 'px' }}>
						{
							this.state.companies.length ? 
							
							this.state.companies.map((company, index) => {
								return (<CompanyList company={company} key={index}/>)
							})
							 : null
						}
						</Row>



					</Container>

				</Container>


				<Container fluid className="footer">
					<Container>
						<Row>

							<Footer />

						</Row>

					</Container>
				</Container>
				{this.state.isLoader ? <Loader /> : null}
			</>

		)
	}

}
export default withRouter(Root(Store));
