import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Banner from '../component/banner';
import BannerFrom from '../component/bannerfrom';
import QuickLink from '../component/quicklinks';
import Companies from '../component/company';
import Footer from '../component/footer2';
import Root from '../hoc/Root.hoc';
import { withRouter } from "react-router";
import CompanyService from '../services/Company.service';
import homeSection from "../resources/img/homeSection.png";
import leftarrow from "../resources/img/left-arrow.png";
import rightarrow from "../resources/img/right-arrow.png";

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			companies: [],
			quicklinksData: [{
				image: require("../resources/img/product6.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title and make up the bulk.",
				rating: 5,
				price: 20,
				isNew: true
			},
			{
				image: require("../resources/img/product4.png"),
				title: "Chair",
				desc: "The text to build on the card title and make up the bulk of the card's content.",
				rating: 2,
				price: 180,
				isNew: false
			},
			{
				image: require("../resources/img/product2.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
				rating: 3,
				price: 120,
				isNew: false
			},
			{
				image: require("../resources/img/product4.png"),
				title: "Card Title",
				desc: "Some quick example text to build on and make up the bulk of the card's content.",
				rating: 4,
				price: 70,
				isNew: false
			},
			{
				image: require("../resources/img/product4.png"),
				title: "Chair",
				desc: "Some quick example on the card title and make up the bulk of the card's content.",
				rating: 2,
				price: 180,
				isNew: false
			},
			{
				image: require("../resources/img/product2.png"),
				title: "Card Title",
				desc: "Some quick example text to build on the card title.",
				rating: 3,
				price: 120,
				isNew: false
			}
			]
		}
	};

	goToSignup = () => {
	}

	componentDidMount() {
		this.getCompanies()
	}
	shouldComponentUpdate(newProps, newState) {
		return true;
	}
	componentDidUpdate(prevProps, prevState) {
		//
	}
	componentWillUnmount() {
		//
	}

	getCompanies = () => {
		CompanyService.getCompanies().then(data => {
			
			if (data && data.success) {
				this.setState({ companies: data.data })
			}
		}).catch(err => {
			//
		})
	}

	render() {

		let links = [];

		this.state.quicklinksData.map((link, ind) => (
			links.push(<QuickLink
				image={link.image}
				title={link.title}
				desc={link.desc}
				rating={link.rating}
				key={ind}
				price={link.price}
				isNew={link.isNew}
			/>)
		))

		let companies = []
		let coms = [];
		let count = 0;
		
		for (var i = 0; i < this.state.companies.length; i++) {
			if (count < 6 ) {
				coms.push(this.state.companies[i]);

				if (i == (this.state.companies.length-1)) {
					companies.push(coms);
				} else {
					if (coms.length == 6) {
						companies.push(coms);
						coms = [];
						count = 0;
					}
				}
			}
		}

		return (
			<>
				{/*<Container fluid className="theme-yellow pt-4 pb-4 banner">
					<Container>
						<Row>

						<Banner
							goToSignup={() => this.goToSignup()}
						/>


						</Row>


					</Container>
				</Container>*/}

				<BannerFrom />

				<Container fluid className="pt-5 pb-5 text-center">
					<Container>
						<Row>

							<img src={homeSection} className="img-responsive" />

							<h3 className="header pb-4 pt-4 mb-2 theme-font">Never Again Remember the Specific Products Your Business Consumes!</h3>
							<iframe width="1280" height="720" src="https://www.youtube.com/embed/8PG_ryWja4E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style={{ padding: "23px", "marginLeft": "auto", "marginRight": "auto" }}></iframe>

						</Row>
					</Container>
				</Container>

				{/*<Container fluid className="pt-4 pb-4 linkx">

			    <Container>
				  <Row>
				  	
				  	<h3 className="header2 pb-4 pt-4 mb-2 theme-font"><span className="span-bold">Axenomic</span> Quick Picks</h3>
				  	<Carousel controls={true} indicators={false} prevIcon={ <img src={require("../resources/img/left-arrow.png")}className=""alt="Axenomic logo" />}
				  	nextIcon={ <img src={require("../resources/img/right-arrow.png")} style= {{right:-20}} alt="Axenomic logo" />}>
						 <Carousel.Item>
				  		{links}
					  </Carousel.Item>
					  <Carousel.Item>
				  		{links}
					  </Carousel.Item>
					  <Carousel.Item>
				  		{links}
					  </Carousel.Item>
					 </Carousel>
			      </Row>
			     
				</Container>
			</Container>*/}

				<Container fluid className=" pos-relative height background3 pb-4 pt-4" id="about-us">
					<Container>
						<Row>

							<span className="yellow-back-right"></span>
							<Col xs={12} md={6} className="float-left"></Col>
							<Col xs={12} md={6} className="float-left back-white pt-4 pb-4">

								<Card style={{ width: '100%' }} className="custom-card">
									<Card.Body>
										<Card.Title className="header pb-2 theme-font color-white"><span className="span-bold"> About </span> us</Card.Title>
										<Card.Subtitle className="mb-2 pb-2 theme-font subheader2 color-white"><span className="span-bold"> How </span> this got started </Card.Subtitle>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											As my entrepreneurial wife journeyed through starting and growing her new business, cognizant of how her time was invested, and I (as her husband) drove to facilitate new solutions to cut non-value added time spent managing the business.
										</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											One time waster is simply restocking essential items the business needs. This simple task introduces challenges such as which printer toner to buy or label maker refills. When delegating this task, employees struggled balancing cost versus quality through many options.
										</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											**Challenge**: How might we streamline supplies restocking and deliver a consistent cost and quality of selection?
		                  				</Card.Text>

									</Card.Body>
								</Card>

							</Col>

						</Row>

					</Container>
				</Container>
				<Container fluid className=" pos-relative height background2 pb-4 pt-4" id="whatAx">
					<Container>
						<Row>

							<span className="yellow-grey-left"></span>
							<Col xs={12} md={6} className="float-left back-white pt-4 pb-4">

								<Card style={{ width: '100%' }} className="custom-card">
									<Card.Body>

										<Card.Subtitle className="mb-2 pb-2 theme-font subheader2 color-white"><span className="span-bold"> What </span> is Axenomic</Card.Subtitle>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											Where are you spending your time to manage your business? How much is directly delivering value to your customers?
	      			  					</Card.Text>
										<Card.Text className="pb-0 theme-font basic-text color-white">
											<span className="span-bold">Axenomic</span> = Axe + Economic<br />
											Cutting wasted time out of restocking business supplies
		                  				</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											Built with the needs of start-ups, small, and medium sized business, Axenomic targets waste in reordering supplies by supporting you and your employees to grow your business
		                  				</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											<span className="span-bold">Featuring </span><br />
											- Custom library of products needed to operate your business<br />
											- Easy visibility to pricing details and availability<br />
											- Empower employees to restock the right supplies. (As pre-selected by you)<br />
                    						- Available security filter keep product utilization private to only your users  <br />
											- Custom business library accessible anywhere<br />

										</Card.Text>

									</Card.Body>
								</Card>

							</Col>
							<Col xs={12} md={6} className="float-left"></Col>
						</Row>

					</Container>
				</Container>

				<Container fluid className=" pos-relative height background2 pb-4 pt-4" id="howAx">
					<Container>
						<Row>

							<span className="yellow-back-right"></span>
							<Col xs={12} md={6} className="float-left"></Col>
							<Col xs={12} md={6} className="float-left back-white pt-4 pb-4">

								<Card style={{ width: '100%' }} className="custom-card">
									<Card.Body>

										<Card.Subtitle className="mb-2 pb-2 theme-font subheader2 color-white"><span className="span-bold"> How </span> Axenomic works</Card.Subtitle>
										<Card.Text className="pb-0 theme-font basic-text color-white">
										As a business owner or manager, build a custom library of supplies needed using our search feature. Easily reference product price, details, and availability all from your Axenomic dashboard.</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
										Ready for a refill? Your library is assessible anywhere, anytime, and by anyone within your team. Simply select the needed item(s), and your order will be securely processed and fulfilled by Amazon.</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
										Lean thinking aims to eliminate waste from your work processes. "Transportation" is one of the 7 wastes accounting for wasted time, resources, and costs when unnecessarily moving products and materials. Axenomic applies this thinking to how you make decisions by reducing the transportation of information processing steps needed for you to take action.</Card.Text>
										<Card.Text className="pb-2 theme-font basic-text color-white">
											By the way, Axenomic is 100% free! Free today, free forever!  Seriously, payment information or  personal details aren't even collected. This platform's mission is to help businesses grow by redirecting your valuable time. </Card.Text>
						  				<Card.Text className="pb-2 theme-font basic-text color-white">
						  					Are you ready to drive out waste in your business?</Card.Text>
										<Button as={Link} to="/signup" variant="primary" className="buy-button button-bg">Join Free</Button>
									</Card.Body>
								</Card>

							</Col>

						</Row>

					</Container>
				</Container>
				{
					this.state.companies.length ?
						<Container fluid className="pt-4 pb-4 linkx">
							<Container>
								<Row>

									<Carousel
										controls={true}
										indicators={false}
										prevIcon={<img src={leftarrow} className="" alt="Axenomic logo" />}
										nextIcon={<img src={rightarrow} style={{ right: -20 }} alt="Axenomic logo" />}
										className="width100"
									//onSlide={()=>{}}
									>

										{
											companies.map((elem, index) => {
												return (
													<Carousel.Item key={index}>
														{
															elem.map((company, index2) => {
																return (
																	<Companies company={company} key={index2} />
																)
															})
														}
													</Carousel.Item>
												)
											})
										}

									</Carousel>

								</Row>

							</Container>
						</Container> : null
				}
				<Container fluid className="footer">
					<Container>
						<Row>

							<Footer />

						</Row>

					</Container>
				</Container>

			</>

		)
	}

}
export default withRouter(Root(Home));
