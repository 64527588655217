import React from 'react'
import Swal from 'sweetalert2'
import store from '../store/index.store'
import AuthAction from '../store/actions/Auth.action'
import { withRouter } from "react-router";
import AuthService from "../services/Auth.service";
const CryptoJS = require("crypto-js");
const config = require('../config/Credential.config');


function Common(OriginalComponent) {
    class NewComponent extends React.Component {
        constructor(props) {
            super(props)
        }
        


        common = {
            makeId: (length) => {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            promiseAlert: (message = null, title = "error", showCancelBtn = false) => { //error, warning, default
                return Swal.fire({
                    title: title == 'error' ? 'Error !' : 'Success !',
                    text: message ? message : 'no message',
                    icon: title,
                    showCancelButton: showCancelBtn,
                    confirmButtonText: 'OK',
                    cancelButtonText: 'No'
                })
            },
            logOut: () => {
                
                localStorage.removeItem('_token')
                localStorage.removeItem('_domain_key')
                localStorage.removeItem('_domain');
                store.dispatch(AuthAction.logOut(true))
                this.props.history.push('/signin')
            },
            getLocalStorage: (key) => {
                return localStorage.getItem(key);
            },
            setLocalStorage: (key, value) => {
                localStorage.setItem(key, value);
            },
            getGenerateKey: () => {
                return this.common.makeId(20);
            },
            updateUserInRedux: (user) => {
                store.dispatch(AuthAction.getLoginAction(user))
            },
            updateToken: (_id) => {
                AuthService.updateToken({_id:_id}).then(res => {
                    if (res && res.success) {
                        this.common.setLocalStorage("_token", res.data);
                    } else {
                        this.common.promiseAlert(res.message);
                    }
                }).catch(err => {
                    this.common.promiseAlert(err);
                });
            }
        }

        render = () => {
            return <OriginalComponent {...this.props} hoc={this.common} />
        }
    }
    return withRouter(NewComponent)
}

export default Common
