class BaseService {
    constructor() {
        this.serviceUrl = undefined
    }

    post = (body, token, formData = false) => {
        if (this.serviceUrl != undefined) {
            return formData ? 
                fetch(this.serviceUrl, {
                    method: 'POST',
                    body: body
                })
                : fetch(this.serviceUrl, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": token ? "Bearer " + token : undefined
                    },
                    body: JSON.stringify(body)
                })
        }
    }

    // postFetchObject = (body, header, token) => {
    //     return header ? {
    //         method: "POST",
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             "Authorization": token ? "Bearer " + token : undefined
    //         },
    //         body: JSON.stringify(body)
    //     } : {
    //         method: "POST",
    //         body: body
    //     }
    // }

    get = (token = undefined) => {
        if (this.serviceUrl != undefined) {
            return fetch(this.serviceUrl, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": token ? "Bearer " + token : undefined
                }
            })
        }

    }
    // post = (body = undefined, headers = true, token = undefined) => {
    //     if (this.serviceUrl != undefined) {
    //         return fetch(this.serviceUrl, this.postFetchObject(body, headers, token))
    //     }
    // }
}

export default BaseService;