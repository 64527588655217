import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import Root from '../hoc/Root.hoc';
import { withRouter } from "react-router";
import Bussiness from '../component/bussiness';
import QuickLink from '../component/quicklinks';
import Footer from '../component/footer2';
import NewRequest from '../component/newrequest';
import ProductService from '../services/Product.service';
import Loader from '../component/loader';
import Auth from '../store/actions/Auth.action'
import { connect } from 'react-redux'
import CartAction from '../store/actions/Cart.action'
import config from "../config/Credential.config";
const jwt = require('jsonwebtoken');
const hash = require("password-hash");

class BussinessInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isNewRequest: false,
			isLoader: true,
			companyProducts: {
				company_details: {
					company_id: "",
					domain: ""
				},
				products: [],
			},
			searchKey: "",
			active: true,
			employeeKey: null
		}
		this.allCompanyProducts = []
	};

	goToSignup = () => {
		
	}

	getProducts = () => {
		let domain = this.props.history.location ? this.props.history.location.pathname ? this.props.history.location.pathname.replace("/", "") : null : null
		if (domain) {
			this.props.hoc.setLocalStorage("_domain", this.props.match.params.domain)
			ProductService.getProducts(domain).then(data => {
				if (data.success) {
					this.props.hoc.setLocalStorage("_employeeKey",  `${data.data.company_details.loginKey}`)
					this.props.hoc.setLocalStorage("business_name",  `${data.data.company_details.business_name}`)
					this.allCompanyProducts = data.data.products ? data.data.products : []
					let _token = this.props.hoc.getLocalStorage("_employeeToken");
					if (_token) {
						try {
							const decoded = jwt.verify(_token, config.jwtSecret);
							if (decoded && decoded.data) {
								if (hash.verify(decoded.data, data.data.company_details.loginKey)) {
									this.props.hoc.setLocalStorage("_employeeKey",  `${data.data.company_details.loginKey}`)
									this.props.hoc.setLocalStorage("_domain", this.props.match.params.domain)
									this.props._login(_token)
									this.props.save_cart_in_onload();
									this.setState({active: true})
								} else {
									localStorage.removeItem("_employeeToken");
									localStorage.removeItem("_employeeKey");
									localStorage.removeItem("_domain");
									this.setState({active: data.data.company_details.productShow})
								}
							} else {
								this.setState({active: data.data.company_details.productShow})
							}
						} catch (error) {
							this.props.hoc.cache();
							window.location.reload();
						}
						
						
					} else {
						this.setState({active: data.data.company_details.productShow})
					}
					this.setState({ companyProducts: data.data, isLoader: false })
				}
			}).catch(err => {
				//
			})
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getProducts()
		setTimeout(() => { 
			this.props.hoc.cache();
			this.getProducts()
		 }, 3000000);
	}
	shouldComponentUpdate(newProps, newState) {
		return true;
	}
	componentDidUpdate(prevProps, prevState) {
		
	}
	componentWillUnmount() {
		
	}
	closeModal() {
		this.setState({ "isNewRequest": false })
	}

	searchProduct = async() => {
		if(this.state.searchKey != "") {
			let products = await this.allCompanyProducts.filter(p => p.DisplayValue.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) != -1)
			this.setState(
				{
					...this.state,
					companyProducts: {
						...this.state.companyProducts,
						products: products
					}
				}
			)
		}
		else {
			this.setState(
				{
					...this.state,
					companyProducts: {
						...this.state.companyProducts,
						products: this.allCompanyProducts
					}
				}
			)
		}
	}

	render() {

		let links = [];
		
		this.state.companyProducts.products.length &&
			this.state.companyProducts.products.map((link, ind) => {
				link['quantity'] = 1;
				links.push(<QuickLink
					item={link}
					image={link.ImageURL}
					title={link.DisplayValue}
					key={link.ASIN}
					price={link.Amount}
					gridWidth="col-md-3"
					active={this.state.active}
					_domain = {this.props.match.params.domain}
					accessProduct={() => {this.setState({active: true})}}
					_login={(token) => {this.props._login(token)}}
					onLoder={() => {this.setState({isLoader: true})}}
					offLoder={() => {this.setState({isLoader: false})}}
					lastOrderShow={true}
				/>)
			})

		return (
			<>
				<Container fluid className="pt-4 pb-4">
					<Container>
						<Row>
							<Bussiness {...this.props}/>
						</Row>
						<hr />
					</Container>
				</Container>

				<Container fluid className="pt-2 pb-4 linkx">
					<Container>

						<Row>
							<Col xs={12} md={6}>
							<h3 className="header2 pb-2 pt-2 mb-4 theme-font"><span className="span-bold">{this.state.companyProducts.company_details.business_name}</span> Custom Supply Library</h3>
							</Col>
							<Col xs={12} md={6} className="text-right">
								<Button variant="primary" className="theme-button2 pl-4 pr-4 mb-2 p-2" type="submit" onClick={() => { this.setState({ isNewRequest: true }) }} >
									Request New Product
							  </Button>

							</Col>


						</Row>
						<Row>
							<Col xs={12} md={4} className="pt-0 mt-0 mb-4">
								<Form className="inline-search">
									<Form.Group controlId="formBasicEmail">

										<Form.Control type="text" placeholder="Search" onChange={ async(e) => {
											e.preventDefault()
											await this.setState({searchKey: e.target.value})
											this.searchProduct()
										}} value={this.state.searchKey}/>

									</Form.Group>
									<Button variant="primary" onClick={this.searchProduct}>

									</Button>
								</Form>
							</Col>
						</Row>

						<Row>

						{links.length?
							<Col>
								{!this.state.active?
									<h4 className="theme-font small-font pb-3 color-red">Product Supply Library in Private Mode - Please enter Private Key to Access</h4>
									:null}
								{links}
							</Col>
							
							:<Col className="text-center pb-5"> <p className="noitem mt-0 mb-5 pb-5"> No Products in Library to Display </p> </Col>
						}
							<hr />

							{/* <Carousel controls={true} indicators={false} prevIcon={<img src={require("../resources/img/left-arrow.png")} className="" alt="Axenomic logo" />}
								nextIcon={<img src={require("../resources/img/right-arrow.png")} style={{ right: -20 }} alt="Axenomic logo" />}>
								<Carousel.Item>
									{links}
								</Carousel.Item>
								<Carousel.Item>
									{links}
								</Carousel.Item>
								<Carousel.Item>
									{links}
								</Carousel.Item>
							</Carousel> */}

						</Row>

					</Container>

				</Container>


				<Container fluid className="footer">
					<Container>
						<Row>

							<Footer />

						</Row>

					</Container>
				</Container>
				<NewRequest isModalShow={this.state.isNewRequest} closeModal={() => { this.closeModal() }} hoc={this.props.hoc} company_id={this.state.companyProducts.company_details.company_id}/>

				{this.state.isLoader ? <Loader /> : null}
			</>

		)
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
	  _login: (data) => dispatch(Auth.getEmployeeLogin(data)),
	  cart_action: (data) => dispatch(CartAction.getTemporaryCartAction(data)),
	  save_cart_in_onload: () => dispatch(CartAction.onLoadCartSave())
	}
}
let mapStateToProps = ({ EmployeeAuthReducer, cartReducer }) => {
	let { isLoggedIn } = EmployeeAuthReducer
	let { temp_cart } = cartReducer
	return {
	  isLoggedIn, temp_cart
	}
}


export default withRouter(Root(connect(mapStateToProps, mapDispatchToProps)(BussinessInfo)));
