import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ProductService from '../services/Product.service';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AuthService from '../services/Auth.service';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Auth from '../store/actions/Auth.action'
import Loader from '../component/loader';
import PreLogin from '../component/prelogin';
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login';
import Root from '../hoc/Root.hoc'

const config = require('../config/Credential.config')

class NewRequset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrelogin: false,
      isModalShow: false,
      marginTop: 0,
      marginTop2: 0,
      email: "",
      password: "",
      isLoader: false,
      validator: {
        email: null,
        password: null,
      },
      requstedProducts: [],
      addToAccept: []
    }
    this.copyState = this.state
    this.copyValidator = this.state.validator
  };

  componentDidMount = () => {
    this.getAllRequests()
  }

  getAllRequests = () => {
    let token = this.props.hoc.getLocalStorage("_token")
    if (token) {
      ProductService.getAllRequests(token).then((data) => {
        
        if (data && data.success)
          this.setState({ requstedProducts: data.data, addToAccept: [] })
          this.copyState = this.state
      }).catch(err => {
        //
      })
    } 
  }

  acceptProducts = (e) => {
    e.preventDefault()
    if (this.state.addToAccept.length) {
      let token = this.props.hoc.getLocalStorage("_token")
      if (token) {
        ProductService.saveProdFromReq(this.state.addToAccept, token).then(async data => {
          await this.props.hoc.promiseAlert("Products have been added to company library", "success");
          this.getAllRequests()
        })
      }
      else
        this.props.hoc.promiseAlert("Token not found", "error");
    }
    else {
      this.props.hoc.promiseAlert("Choose an item to proceed", "error");
    }
  }



  render() {

    //let marginTop = document.getElementById("signinFrom").clientHeight

    return (

      <>

        <Container fluid className="pt-4 pb-4 signin theme-yellow" style={{ minHeight: window.innerHeight - 80 + 'px' }}>
          <Container id="mainContainer">
            <Row>
              <Col xs={12} md={6}>
                <h3 className="header2 pb-2 pt-2 mb-4 theme-font"> New Product Requests </h3>
              </Col>

              {
                this.state.requstedProducts.length ?
                  this.state.requstedProducts.map((item, i) => {
                    return (
                      <span className={ !item.accept ? 'listy mb-3 active-list' : 'listy mb-3 inactive-list'} key={i}>
                        <InputGroup>
                          <InputGroup.Prepend className="col-sm-1 p-1">
                            <InputGroup.Checkbox aria-label="Radio button for following text input" checked={item.isSelected ? true : false} value={item.isSelected ? true : false} onChange={async () => {
                              item['isSelected'] = !item['isSelected']
                              let itemExists = await this.state.addToAccept.findIndex(p => p._id === item._id)
                              if (itemExists == -1)
                                this.setState({ addToAccept: [...this.state.addToAccept, item] })
                              else {
                                let requested_products = this.state.addToAccept
                                await requested_products.splice(requested_products.findIndex(p => p._id === item._id), 1)
                                this.setState({ addToAccept: requested_products })
                              }

                            }} />
                          </InputGroup.Prepend>
                          <div className="col-sm-10">
                            <p className="email mr-2 req-cont mb-1"><strong>Employee Email ID:-  </strong> <span>{item.email ? item.email : ""}</span> </p>
                            <a href={item.product_url} className="web req-cont" target="new"><strong>Product URL:- </strong> <span>{item.product_url ? item.product_url : ""}</span></a>
                          </div>
                          <div className="col-sm-1 p-1 text-center">
                            <span className={item.accept ? ' isAcceptNew' : "isPendingNew"}> {item.accept ? ' accepted' : "Pending"}</span>
                          </div>
                        </InputGroup>
                      </span>
                    )
                  }) : <span className='listy mb-3 active-list' key={this.props.hoc.makeId(2)}>
                    <p className="noitemNewRequest"> No Products Request </p>
                  </span>
                  // <p className="noitemNewRequest"> No Products Request </p>
              }

              {/* <label className="listy active-list mb-3">
                
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox aria-label="Radio button for following text input" checked />
                  </InputGroup.Prepend>
                  <p className="email mr-2 req-cont"><strong>Employee Email ID:-  </strong> <span>abc@jjj.com</span></p>
                  <p className="web req-cont"><strong>Product URL:- </strong> <span>www.google.com</span></p>
                </InputGroup>
              </label>

              <label className="listy inactive-list mb-3">
                
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox aria-label="Radio button for following text input" checked />
                  </InputGroup.Prepend>
                  <p className="email mr-2 req-cont"><strong>Employee Email ID:-  </strong> <span>abc@jjj.com</span></p>
                  <p className="web req-cont"><strong>Product URL:- </strong> <span>www.google.com</span></p>
                </InputGroup>
              </label> */}

            </Row>
            <Row>
              {
                this.state.requstedProducts.length ? <Button variant="primary" className="theme-button2 pl-4 pr-4 mb-2 p-2" onClick={this.acceptProducts} >
                Accept
                </Button> : null
              }
              
              <Button variant="primary" as={Link} to="/" className="theme-button2 pl-4 pr-4 mb-2 p-2 ml-3" type="" >
                Back to profile
                </Button>
            </Row>
          </Container>
        </Container>

      </>

    )
  }

}

let mapDispatchToProps = (dispatch) => {
  return {
    _login: (data) => dispatch(Auth.getLoginAction(data)),
  }
}
let mapStateToProps = ({ authReducer }) => {
  let { isLoggedIn, test } = authReducer
  return {
    isLoggedIn: isLoggedIn
  }
}

export default withRouter(Root(connect(mapStateToProps, mapDispatchToProps)(NewRequset)))
