import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import $ from 'jquery'

class LogoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalShow: true
    }
  };

  static getDerivedStateFromProps(props, state) {

    return {
      isModalShow: props.isModalShow
    };

  }

  componentDidMount = () => {
    $(document).on('keydown', (evt) => {
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode == 27) {
				this.setState({isModalShow: false})
			}
			return true;
		})
  }

  render() {

    return (

      <>

        <Modal show={this.state.isModalShow} >
          <Modal.Header >
            <Modal.Title>Are you sure you want to logout</Modal.Title>
          </Modal.Header>
          
          <Modal.Footer>
             <Button className=" btn btn-primary theme-button  pl-4 pr-4 mb-2" onClick={() => { this.props.logout() }} >Yes</Button>
              <Button className=" btn btn-primary theme-button theme-button2  pl-4 pr-4 mb-2" onClick={() => { this.props.closeModal() }}>No</Button>

         
          </Modal.Footer>
        </Modal>
      </>

    )
  }

}
export default LogoutModal;