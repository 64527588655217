import React from 'react';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Common from '../hoc/Common.hoc';
import {serviceUrl} from '../config/Credential.config'
import {
  Link
} from "react-router-dom";
import default_images from "../resources/img/images.png"

class Companies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // image: require("../resources/img/product4.png"),
      // title: "Card Title",
      // desc: "Some quick example text to build on the card title and make up the bulk of the card's content.",
      // rating: 3,
      // key: Math.floor(Math.random() * 100),
      // price: 150,
      // isNew: false,
      gridWidth: "col-md-2",
      company: {
        business_logo: "",
        domain: ""
      },
      key: "",
      mrTop:0
    }
  };


  static getDerivedStateFromProps(props, state) {
    return {
      gridWidth: "col-md-2",
      company: props.company ? props.company : {business_logo: "", domain: ""},
      key: props.key ? props.key : ""
    }
    // return {
    //   image: props.image,
    //   title: props.title,
    //   desc: props.desc,
    //   rating: props.rating,
    //   price: props.price,
    //   isNew: props.isNew,
    //   gridWidth: props.gridWidth ? props.gridWidth : "col-md-2"
    // };

  }
  setMargin(id, src){

    // var img1 = document.createElement("img");
    // img1.src = src;
    // img1.onload = () => {
       
    // };
    
    var elmnt = document.getElementById(id);
    var mrTop = (160 - parseFloat(elmnt.offsetHeight)) / 2;

    if(mrTop > 0){
      this.setState({mrTop:mrTop})
    }
    
  }

  render() {
    let imgId = Math.floor((Math.random() * 100000) + 1);
    return (
      <>
        <Card as={Link} to={`/${this.state.company.domain}`} className={this.state.gridWidth + " custom-card fix-height col-xs-12 float-left"} key={this.state.key}>
          {
            this.state.company.business_logo ? 
            <Figure className="text-center comp">
            
              <Figure.Image
                width="120px"
                //height={180}
                alt="171x180"
                id={imgId}
                //style={{marginTop:this.state.mrTop}}
                onLoad={()=>{this.setMargin(imgId,serviceUrl+'company_logo/'+this.state.company.business_logo)}}
                src={serviceUrl+'company_logo/'+this.state.company.business_logo}
              />

            </Figure>
            : 
            <Figure className="text-center comp">
            
              <Figure.Image
                width="120px"
                //height={180}
                alt="171x180"
                id={"img"+default_images}
                //style={{marginTop:this.state.mrTop}}
                onLoad={()=>{this.setMargin("img"+default_images)}}
                src={default_images}
              />

            </Figure>
          }
          


        </Card>
        {/* <Card as={Link} to="/bussiness" className={this.state.gridWidth + " custom-card col-xs-12 float-left"} key={this.props.hoc.makeId(10)}>

          <Figure className="text-center comp">
            <Figure.Image
              width="120px"
              //height={180}
              alt="171x180"
              src={require("../resources/img/hcl.png")}
            />

          </Figure>


        </Card>
        <Card as={Link} to="/bussiness" className={this.state.gridWidth + " custom-card col-xs-12 float-left"} key={this.props.hoc.makeId(10)}>

          <Figure className="text-center comp">
            <Figure.Image
              width="120px"
              //height={180}
              alt="171x180"
              src={require("../resources/img/google-logo.png")}
            />

          </Figure>


        </Card>
        <Card as={Link} to="/bussiness" className={this.state.gridWidth + " custom-card col-xs-12 float-left"} key={this.props.hoc.makeId(10)}>

          <Figure className="text-center comp">
            <Figure.Image
              width="120px"
              //height={180}
              alt="171x180"
              src={require("../resources/img/tcs.png")}
            />

          </Figure>


        </Card>
        <Card as={Link} to="/bussiness" className={this.state.gridWidth + " custom-card col-xs-12 float-left"} key={this.props.hoc.makeId(10)}>

          <Figure className="text-center comp">
            <Figure.Image
              width="120px"
              //height={180}
              alt="171x180"
              src={require("../resources/img/hcl.png")}
            />

          </Figure>


        </Card>
        <Card as={Link} to="/bussiness" className={this.state.gridWidth + " custom-card col-xs-12 float-left"} key={this.props.hoc.makeId(10)}>

          <Figure className="text-center comp">
            <Figure.Image
              width="120px"
              //height={180}
              alt="171x180"
              src={require("../resources/img/wipro.png")}
            />

          </Figure>


        </Card> */}

      </>

    )
  }

}
export default Common(Companies);